import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Skeleton, Select, MenuItem, TextField, InputLabel, FormControl, Card, CardContent, Icon, CardMedia, Divider} from '@mui/material';
import { MathJax } from 'better-react-mathjax';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const HomeTabFeed = () => {

    // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
    function stringToColor(stringInput) {
        let stringUniqueHash = [...stringInput].reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
    }

    let [userCourses, setUserCourses] = React.useState([])

    let [feed, setFeed] = React.useState(null)

    const [classFilter, setClassFilter] = React.useState("all");

    const [sort, setSort] = React.useState("Trending");

    const maxInc = 10
    const [max, setMax] = React.useState(10)

    React.useEffect(() => {
        let xhr = new XMLHttpRequest();
        let url = "/api/courses"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                let resp_courses = resp_json["courses"]
                let temp = []
                for (let i = 0; i < resp_courses.length; i++) {
                    temp.push(
                        <MenuItem key={i} value={resp_courses[i]}>{resp_courses[i]}</MenuItem>
                    )
                }
                setUserCourses(temp)
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        xhr.send()
    }, [])


    React.useEffect(() => {

        setMax(maxInc)

        setFeed(null)

        // loads cached feed
        if (sort == "Trending" || sort == "Popular") {
            let now = Math.floor(Date.now() / 1000)
            let last_feed_time = sessionStorage.getItem("feed_time" + sort + classFilter)
            if (now - last_feed_time < (15 * 60)) {
                let cached_feed = JSON.parse(sessionStorage.getItem("feed" + sort + classFilter))
                setFeed(cached_feed)
                return;
            }
        }

        let out = {
            class: classFilter,
            sort: sort
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/feed"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText)
                setFeed(resp_json)
                if (sort == "Trending" || sort == "Popular") {
                    let now = Math.floor(Date.now() / 1000)
                    sessionStorage.setItem("feed_time" + sort + classFilter, now)
                    sessionStorage.setItem("feed" + sort + classFilter, JSON.stringify(resp_json))
                }
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }, [classFilter, sort])

    const handleChangeClassFilter = (event) => {
        setClassFilter(event.target.value);
    };

    const handleChangeSort = (event) => {
        setSort(event.target.value);

        // change to sort
    };


    // https://stackoverflow.com/questions/63501757/check-if-user-reached-the-bottom-of-the-page-react
    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (bottom) {
            setMax(max + maxInc)
        }
    };
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [max]);
    
    const renderCards = () => {
        let out = []
        let card_count = max;
        if (feed["feed"].length < max) {
            card_count = feed["feed"].length
        }
        for (let i = 0; i < card_count; i++) {
            let feedItem = feed["feed"][i]
            if (feedItem[0] == "question") {
                out.push(
                    <Button sx={{mb: 2, width:"100%", maxWidth:900, position:"relative", left:"50%", transform:"translateX(-50%)", textTransform: "none"}} component={Link} to={"/question/" + feedItem[2]}>
                    <Card sx={{width:"100%", bgcolor:"#F6F6F6"}}>
                        <CardContent>
                        <Typography variant="h5">{feedItem[5]}</Typography>
                            <Typography variant="overline">@{feedItem[6]}</Typography>
                            <Typography sx={{mt:2}} variant="body2">
                                <MathJax dynamic hideUntilTypeset={"first"} inline>{feedItem[4]}</MathJax>
                            </Typography>
                            {( feedItem[3] != "none"
                            ?
                            <Box
                                component="img"
                                src={feedItem[3]}
                                sx={{mt:2, height:50, borderRadius:2}}
                            />
                            :
                            ""
                            )}
                            <Box sx={{mb:-1.5, mt:2, display:"flex"}}>
                                <Typography sx={{mr:2}} color="primary" variant="subtitle2"> <Icon color="primary" sx={{position:"relative", top:4}} fontSize="small" component={ArrowUpwardIcon} /> {feedItem[1]}</Typography>
                                <Chip sx={{bgcolor:stringToColor(feedItem[7]), color:"white"}} label={feedItem[7]} />
                            </Box>
                        </CardContent>
                    </Card>
                    </Button>
                )
            }
            else if (feedItem[0] == "file") {
                out.push(
                    <Button sx={{mb: 2, width:"100%", maxWidth:900, position:"relative", left:"50%", transform:"translateX(-50%)", textTransform: "none"}} component={Link} to={"/file/" + feedItem[2]}>
                    <Card sx={{width:"100%", bgcolor:"#F6F6F6"}}>
                        <CardContent>
                            <CardMedia 
                                sx={{mb:1}}
                                component="img"
                                height="350"
                                image={feedItem[3]}
                            />
                            <Typography variant="h5">{feedItem[5]}</Typography>
                            <Typography variant="overline">@{feedItem[6]}</Typography>
                            <Typography sx={{mt:2}} variant="body2">
                                <MathJax dynamic hideUntilTypeset={"first"} inline>{feedItem[4]}</MathJax>
                            </Typography>
                            <Box sx={{mb:-1.5, mt:2, display:"flex"}}>
                                <Typography sx={{mr:2}} color="primary" variant="subtitle2"> <Icon color="primary" sx={{position:"relative", top:4}} fontSize="small" component={ArrowUpwardIcon} /> {feedItem[1]}</Typography>
                                <Chip sx={{bgcolor:stringToColor(feedItem[7]), color:"white"}} label={feedItem[7]} />
                            </Box>
                        </CardContent>
                    </Card>
                    </Button>
                )
            }
            if (i >= feed["feed"].length - 1) {
                out.push(
                    <Box>
                        <Box sx={{display:"flex", justifyContent:"center", mt:5}}>
                            <Typography variant='h5'>You're all caught up!</Typography>
                        </Box>
                    </Box>
                )
            }
        }
        if (card_count == 0) {
            out.push(
                <Box>
                    <Box sx={{display:"flex", justifyContent:"center", mt:5}}>
                        <Typography variant='h5'>You're all caught up!</Typography>
                    </Box>
                </Box>
            )
        }
        return out;
    }

    const renderSkeletons = () => {
        let out = []
        for (let i=0; i<5; i++) {
            out.push(
                <Card sx={{width:"100%", bgcolor:"#F6F6F6", mb:2}}>
                    <CardContent>
                        <Typography variant="h5"><Skeleton /></Typography>
                        <Typography variant="overline"><Skeleton /></Typography>
                        <Typography sx={{mt:2}} variant="body2">
                            <MathJax dynamic hideUntilTypeset={"first"} inline><Skeleton /></MathJax>
                        </Typography>
                        <Skeleton sx={{width:"50%"}} />
                        <Skeleton sx={{width:50}} />
                    </CardContent>
                </Card>
            )
        }
        return (
            <Box sx={{height:"50vh", overflow:"hidden"}}>
                <Box sx={{position:"absolute", left:"0px", height:"100%", width:"100%", bgcolor:"green", zIndex:10, background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%)"}}></Box>
                {out}
            </Box>
        )
    }

    return (
        <>
        <Box sx={{maxWidth:900, paddingX:"25px", marginY:2, position:"relative", left:"50%", transform:"translateX(-50%)"}}>
            <Box sx={{display:"flex", flexDirection:"row"}}>
                <Box sx={{ width: 200, mb:5 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Class Filter</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={classFilter}
                        label="Class Filter"
                        onChange={handleChangeClassFilter}
                        >
                            <MenuItem value="all">- All My Classes -</MenuItem>
                            {userCourses}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: 200, mb:5, ml:2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sort}
                        label="Sort"
                        onChange={handleChangeSort}
                        >
                            <MenuItem value="Trending">Trending</MenuItem>
                            <MenuItem value="New">New</MenuItem>
                            <MenuItem value="Popular">Popular</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {( feed != null
            ? 
                renderCards()
            :
                renderSkeletons()
            )}

            <Box sx={{mb:10}}></Box>
            

        </Box>

        </>
    );
}
 
export default HomeTabFeed;