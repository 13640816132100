import * as React from 'react';
import {useState, forwardRef, useImperativeHandle} from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, TextField, Paper, Checkbox} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import CheckIcon from '@mui/icons-material/Check';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const VerifyemailCode = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['login_state_cont']);

    const params = useParams(); //url
    const code = params.code;

    React.useEffect(() => { // runs once on page load

        let out = {
            "token":code,
            "csfr_token":window.localStorage.getItem("cToken")
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/verifyemail"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                

                setCookie("login_state_cour", "true");

                setRedirect(resp_json["redirect"])
            }
            if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                setRedirect("/signup")
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }, [])

    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    return (
        <>
        {renderNavigate()}
        <Box mt="15vh" sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <Box sx={{display:"flex", alignItems:"center"}}>
                    <Box
                    component="img"
                    sx={{
                    width: 55,
                    cursor: "pointer",
                    mr: 2
                    }}
                    alt="Courseology logo"
                    src="/logo.png"
                    />
                    <Typography variant="h4" sx={{height:"100%", poition:"relative"}}>Courseology</Typography>
            </Box>
            <Box sx={{display:"flex", flexDirection:"column", mt:5, textAlign:"center", borderBottom:"1px solid", paddingBottom:2}}>
                <Typography sx={{mb:1}} variant='h6'>Verifying your email...</Typography>
                <Typography>This should only take a few seconds</Typography>
                <Typography>If your token is expired you will need to signup again</Typography>
                <Typography>Tokens will expire after one hour</Typography>
                <Box sx={{display:"flex", margin:"auto", mt:2, borderBottom:"3px solid orange"}}>
                    <HourglassEmptyIcon sx={{mr:1, color:'orange'}} />
                    <Typography sx={{color:'orange'}} variant="button">Verifying...</Typography>
                </Box>
            </Box>
        </Box>
        </>
    );
}
 
export default VerifyemailCode;