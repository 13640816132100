import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel, NativeSelect, Icon} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

const CAuthor = (props) => {
    //props["updateFunc"]("lol")

    const getStartingState = () => {
        if (props["data"]["authors"]) {
            return props["data"]["authors"]
        }
        return [["author","","","","",""]]
    }

    const [authors, setAuthors] = React.useState(getStartingState())

    const handleUpdate = (i, data_index, value) => {
        let tempAuthors = [...authors];
        tempAuthors[i][data_index] = value;
        setAuthors(tempAuthors);
    }

    const addAuthor = () => {
        let tempAuthors = [...authors];
        tempAuthors.push(["author","","","","",""]);
        setAuthors(tempAuthors);
    }

    const handleDelete = (i) => {
        let tempAuthors = [...authors];
        tempAuthors.splice(i, 1);
        setAuthors(tempAuthors);
    }

    React.useEffect(() => {
        props["updateFunc"]("authors", authors)
    }, [authors])

    const renderAuthors = () => {
        let out = []

        for (let i = 0; i < authors.length; i++) {
            out.push(
                <Box key={i} sx={{display:"flex", flexDirection:"row", "padding":0.75, border:"1px solid lightgrey", borderRadius:1, mb:1}}>
                    <NativeSelect
                        defaultValue={authors[i][0]}
                        onChange={(e) => handleUpdate(i,0,e.target.value)}
                        size="small"
                    >
                        <option value={"author"}>Author</option>
                        <option value={"editor"}>Editor</option>
                        <option value={"translator"}>Translator</option>
                        <option value={"contributor"}>Contributor</option>
                    </NativeSelect>
                    <TextField size="small" onChange={(e) => handleUpdate(i,1,e.target.value)} defaultValue={authors[i][1]} sx={{ml:1, width:75}} variant="standard" label="Title"/>
                    <TextField size="small" onChange={(e) => handleUpdate(i,2,e.target.value)} defaultValue={authors[i][2]} sx={{ml:1}} variant="standard" label="* First"/>
                    <TextField size="small" onChange={(e) => handleUpdate(i,3,e.target.value)} defaultValue={authors[i][3]} sx={{ml:1, width:75}} variant="standard" label="MI"/>
                    <TextField size="small" onChange={(e) => handleUpdate(i,4,e.target.value)} defaultValue={authors[i][4]} sx={{ml:1}} variant="standard" label="* Last"/>
                    {( i == authors.length - 1 && i > 0 ?
                    <IconButton onClick={() => handleDelete(i)}><DeleteIcon /></IconButton>
                    :
                    ""
                    )}
                </Box>
            )
        }
        return out;
    }

    return (
        <>
        <Box sx={{maxWidth:500}}>
            <Typography sx={{mb:1}} variant='h6'>Author(s)</Typography>
            {renderAuthors()}
            <Button onClick={addAuthor} sx={{mt:1}} variant="outlined">Add another</Button>
        </Box>
        </>
    );
}
 
export default CAuthor;