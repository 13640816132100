import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Skeleton, Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel, Card, Badge, Menu} from '@mui/material';
import { MathJax } from 'better-react-mathjax';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';

const File = () => {

    const params = useParams(); //url
    const f_id = params.f;

    let out = {
        "file_id":f_id
    }

    React.useEffect(() => {
        let xhr = new XMLHttpRequest();
        let url = "/api/file"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                setRes(resp_json)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }, [])

    const [res, setRes] = React.useState(null);

    const handleVote = (change) => {
        let resCopy = structuredClone(res)
        if (res.voteState == -1 && change == -1) {
            resCopy.voteState = 0;
            resCopy.votes = resCopy.votes + 1;
        }
        else if (res.voteState == 1 && change == 1) {
            resCopy.voteState = 0;
            resCopy.votes = resCopy.votes - 1;
        }
        else if (res.voteState == -1 && change == 1) {
            resCopy.voteState = 1;
            resCopy.votes = resCopy.votes + 2;
        }
        else if (res.voteState == 1 && change == -1) {
            resCopy.voteState = -1;
            resCopy.votes = resCopy.votes - 2;
        }
        else if (res.voteState == 0 && change == 1) {
            resCopy.voteState = 1;
            resCopy.votes = resCopy.votes + 1;
        }
        else if (res.voteState == 0 && change == -1) {
            resCopy.voteState = -1;
            resCopy.votes = resCopy.votes - 1;
        }

        let out = {
            "voteState":change,
            "p_type":"file",
            "p_id":f_id,
            "csfr_token":window.localStorage.getItem("cToken")
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/vote"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                window.localStorage.setItem("cToken", resp_json["csfr_token"])

                setRes(resCopy)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const renderComments = () => {
        let commentsToRender = res.comments;

        if (commentsToRender.length == 0) {
            return [];
        }

        let out = []
        for (let i = 0; i < commentsToRender.length; i++) {
            out.push(
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    {( commentsToRender[i][3] 
                                                                            // p_index will be the index, note that the question will set p_indx to -1 automatically
                        ? <IconButton onClick={() => handleDelete("comment", commentsToRender[i][4], i)} size='small'><DeleteIcon /></IconButton>
                        : <ArrowRightIcon />
                    )}
                    <Box>
                        <Typography variant="overline">{"@" + commentsToRender[i][0]} </Typography> 
                        <Typography variant="overline">{" - " + commentsToRender[i][2]} </Typography> 
                        <Typography sx={{mt:-1}} variant="body2">{commentsToRender[i][1]}</Typography>
                    </Box>
                </Box>
            )
        }
        
        return out;
    }

    const handleNewComment = (e) => {
        let commToAppend = null;

        let val = e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].value
        let username = "none";
        let out = {
            "p_id":f_id,
            "p_type":"file",
            "content":val,
            "csfr_token":window.localStorage.getItem("cToken")
        }
        let xhr = new XMLHttpRequest();
        let url = "/api/comment"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                username = resp_json["cur_user"]
                let comment_id = resp_json["comment_id"]

                commToAppend = [...res.comments]
                commToAppend.push([username, val, "1 second ago", true, comment_id]) // gets value from input
                let tempRes = structuredClone(res)
                tempRes.comments = commToAppend

                setRes(tempRes)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
            setLoading(false)
        }
        let post_json = JSON.stringify(out)
        setLoading(true)
        xhr.send(post_json)
    }

    const handleDelete = (type, id, index) => {
        if (window.confirm("You are about to delete your " + type + ". Click OK to continue.")) {
            if (type == "file") {
                let out = {
                    "id":id,
                    "type":"file",
                    "csfr_token":window.localStorage.getItem("cToken")
                }
                let xhr = new XMLHttpRequest();
                let url = "/api/delete"
                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json")
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        window.localStorage.setItem("cToken", resp_json["csfr_token"])
                        alert("Post deleted successfully")
                    }
                    else if (xhr.readyState === 4 && xhr.status === 500) {
                        alert("Unknow server error!")
                    }
                    else if (xhr.readyState === 4 && xhr.status != 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                    }
                    setLoading(false)
                }
                let post_json = JSON.stringify(out)
                setLoading(true)
                xhr.send(post_json)
            }
            else if (type == "comment") {
                let out = {
                    "id":id,
                    "type":"comment",
                    "csfr_token":window.localStorage.getItem("cToken")
                }
                let xhr = new XMLHttpRequest();
                let url = "/api/delete"
                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json")
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        window.localStorage.setItem("cToken", resp_json["csfr_token"])

                        let commToPop = [...res.comments]
                        commToPop.splice(index, 1)
                        let tempRes = structuredClone(res)
                        tempRes.comments = commToPop

                        setRes(tempRes)

                        alert("Comment deleted successfully")
                    }
                    else if (xhr.readyState === 4 && xhr.status === 500) {
                        alert("Unknow server error!")
                    }
                    else if (xhr.readyState === 4 && xhr.status != 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                    }
                    setLoading(false)
                }
                let post_json = JSON.stringify(out)
                setLoading(true)
                xhr.send(post_json)
            }
        }
    }

    const [loading, setLoading] = React.useState(false)

    return (
        <>
        {(!loading ? "" :
            <Box sx={{position:"fixed", top:"0px", left:"0px", width:"100%", height:"100%", zIndex:"100", bgcolor:"rgba(0,0,0,0.5)", backdropFilter:"blur(3px)", display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <link rel="stylesheet" href="/loader.css"></link>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </Box>
            </Box>
        )}
        <div id="content">
        <TopBar loginState="true"></TopBar>

        <Box sx={{margin:"auto", mt:5, display:"flex", width:"95%", maxWidth:"1000px", flexDirection:"column"}}>

            {(res!=null ? 
            <Box sx={{display:"flex", flexDirection:"column", mb:5}}>
                <iframe style={{height:"75vh"}} src={"/pdfviewer/web/viewer.html?file=" + encodeURIComponent(res["pdf_url"])}></iframe>
                <Box sx={{display:"flex", flexDirection:"row", mt:4}}>
                    <Box sx={{display:"flex", flexDirection:"column", mr:1, alignItems:"center"}}>
                        <IconButton onClick={() => handleVote(1)} size="large"><ArrowUpwardIcon sx={{bgcolor:(res.voteState == 1 ? "#630031" : "lightgrey"), color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                        <Typography>{res.votes}</Typography>
                        <IconButton onClick={() => handleVote(-1)} size="large"><ArrowDownwardIcon sx={{bgcolor:(res.voteState == -1 ? "#630031" : "lightgrey"), color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                    </Box>
                    <Card sx={{padding:1.5, boxSizing:"border-box", backgroundColor:"#F6F6F6", width:"100%"}}>
                        <Typography variant="overline">@{res.author} - </Typography><Typography variant="overline">{res.timeAgo}</Typography>
                        <Typography variant="h5">{res.title}</Typography>
                        
                        <Box sx={{mt:2, display:"flex", flexDirection:"column"}}>
                            <Typography>{res["description"]}</Typography>
                        </Box>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            {(!res.op ? "" :
                                <Button onClick={() => handleDelete("file", f_id, -1)} sx={{ml:"-5px"}} startIcon={<DeleteIcon />}>Delete</Button>
                            )}
                        </Box>
                    </Card>
                </Box>
                <Box sx={{ml:12, mt:2, borderLeft:"2px solid"}}>
                    {renderComments()}
                    <Box sx={{display:"flex", flexDirection:"row", ml:2, mt:2, width:"100%"}}>
                        <TextField label="Reply" sx={{mr:1, maxWidth:275, width:"75%"}} size="small" />
                        <Button onClick={(e) => handleNewComment(e)}>Submit</Button>
                    </Box>
                </Box>
            </Box>
            :
            <Box sx={{display:"flex", flexDirection:"column", mb:5}}>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{display:"flex", flexDirection:"column", mr:1, alignItems:"center"}}>
                        <IconButton size="large"><ArrowUpwardIcon sx={{bgcolor:"lightgrey", color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                        <IconButton size="large"><ArrowDownwardIcon sx={{bgcolor:"lightgrey", color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                    </Box>
                    <Card sx={{padding:1.5, boxSizing:"border-box", backgroundColor:"#F6F6F6", width:"100%"}}>
                        <Typography variant="h5"><Skeleton /></Typography>
                        <Typography variant="overline"><Skeleton /></Typography>
                        <Box sx={{mt:2, display:"flex", flexDirection:"column"}}>

                        </Box>
                        <Box>
                            <Skeleton />
                        </Box>
                    </Card>
                </Box>
                <Box sx={{ml:12, mt:2}}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Box>
            </Box>
            )}
        </Box>

        <Box sx={{mb:10}}></Box>

        </div>
        <Footer></Footer>
        </>
    );
}
 
export default File;