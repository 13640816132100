import * as React from 'react';
import {useState, forwardRef, useImperativeHandle} from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, TextField, Paper, Checkbox} from '@mui/material';
import { Navigate } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const PasswordReset = () => {

    const params = useParams(); //url
    const code = params.code;

    const [loading, setLoading] = React.useState(false)

    const passwordRef = React.useRef(null)
    const cPasswordRef = React.useRef(null)

    const handleSubmit = () => {
        setLoading(true)
        let password = passwordRef.current.value;
        let cPassword = cPasswordRef.current.value;

        if (password.length < 8) {
            alert("Password must 8 characters or longer")
            setLoading(false)
            return;
        }

        if (password != cPassword) {
            alert("Passwords do not match")
            setLoading(false)
            return;
        }

        let out = {
            "token":code,
            "password":password,
            "cPassword":cPassword,
            "csfr_token":window.localStorage.getItem("cToken")
        }
        let xhr = new XMLHttpRequest();
        let url = "/api/passwordreset"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                setRedirect(resp_json["redirect"])
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
            setLoading(false)
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)

    }
    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    return (
        <>
        {(!loading ? "" :
            <Box sx={{position:"fixed", top:"0px", left:"0px", width:"100%", height:"100%", zIndex:"100", bgcolor:"rgba(0,0,0,0.5)", backdropFilter:"blur(3px)", display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <link rel="stylesheet" href="/loader.css"></link>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </Box>
            </Box>
        )}
        {renderNavigate()}
        <Box sx={{display:"flex", alignItems:"center", mt:"20%", flexDirection:"column"}}>
            <Box sx={{display:"flex", alignItems:"center"}}>
                    <Box
                    component="img"
                    sx={{
                    width: 55,
                    cursor: "pointer",
                    mr: 2
                    }}
                    alt="Courseology logo"
                    src="/logo.png"
                    />
                    <Typography variant="h4" sx={{height:"100%", poition:"relative"}}>Courseology</Typography>
            </Box>
            <Box sx={{display:"flex", flexDirection:"column", mt:5, textAlign:"center", alignItems:"center"}}>
                <Typography sx={{mb:1}} variant='h6'>Password Reset</Typography>
                <Typography>Please enter your new password</Typography>
                <Typography>Password must 8 characters or longer</Typography>
                <TextField size='small' inputRef={passwordRef} sx={{mt:3, width:"100%"}} variant="filled" label="Password" type="password" />
                <TextField size='small' inputRef={cPasswordRef} sx={{mt:3, width:"100%"}} variant="filled" label="Confirm Password" type="password" />
                <Button onClick={() => handleSubmit()} size="large" sx={{width:120, mt:2}} variant="contained">Submit</Button>
            </Box>
        </Box>
        </>
    );
}
 
export default PasswordReset;