import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Skeleton, Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel, Card, Badge, Menu} from '@mui/material';
import { MathJax } from 'better-react-mathjax';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';

import QuestionAnswerInput from './QuestionAnswerInput';

const db = {

    quest1:{
        question:{
            title:"How do I do this question from calc 2 hw1 q2?",
            body:[
                ["text", "If you love pcp please hmu, @pcplover29 on insta"],
                ["eq", "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"],
                ["text", "lol"],
                ["image", "https://www.simplilearn.com/ice9/free_resources_article_thumb/Queue_Impl_arr/Enqueue_implementation-Queue_Implementation_Using_Array.png"],
                ["text",""]
            ],
            author:"pcpbythegallon",
            votes:3,
            voteState:1,
            comments:[
                ["man23", "I got you, know a guy witha gallon o' pcp kl lklk lklkl klkkl klkl klklklklkl klklklklkl klkl klkl kl klk  kl kl klkl klk lklklklklkk lklkl klklk lklk lk klkl klkl klkl"],
                ["ihatepcp", "I will find where you live"],
                ["pcpbythegallon", "ok thanks @man23"]
            ]
        },

        answers:[
            {
                accepted:true,
                author:"hellomyfr",
                body:[
                    ["text", "THIS IS AN ANSWer"],
                    ["eq", "\\frac{10}{4x}"],
                    ["text", ""],
                    ["image", "https://i.ytimg.com/vi/okr-XE8yTO8/maxresdefault.jpg"],
                    ["text",""]
                ],
                votes:12,
                voteState:-1,
                comments:[
                    ["man23", "THIS IS A commENT"],
                    ["ihatepcp", "I will find where you live"],
                    ["pcpbythegallon", "ok thanks @man23"]
                ]
            },
            {
                accepted:false,
                author:"hellomyfr",
                body:[
                    ["text", "I GOT accepted losers"],
                    ["eq", "\\frac{10}{4x}"],
                    ["text", ""],
                    ["image", "https://i.pinimg.com/originals/50/ce/18/50ce18c363f5fbf65c851846b5f41532.jpg"],
                    ["text",""],
                    ["image", "https://i.pinimg.com/originals/50/ce/18/50ce18c363f5fbf65c851846b5f41532.jpg"],
                    ["text", ""]
                ],
                votes:4,
                voteState:0,
                comments:[
                    ["man23", "suck my balls"],
                ]
            },
            {
                accepted:false,
                author:"hellomyfr",
                body:[
                    ["text", "hhhhhhhhhhhhhhhhhello"],
                ],
                votes:14,
                voteState:0,
                comments:[
                    ["man23", "THIS IS A commENT"],
                    ["ihatepcp", "I will find where you live"],
                    ["pcpbythegallon", "ok thanks @man23"]
                ]
            },
        ]
    },

    quest2:{
        question:{
            title:"CS 2505 array queue null pointer",
            body:[
                ["text", "This is my enqueue method"],
                ["image", "https://www.simplilearn.com/ice9/free_resources_article_thumb/Queue_Impl_arr/Enqueue_implementation-Queue_Implementation_Using_Array.png"],
                ["text","I don't know why but I keep getting a null pointer exception"]
            ],
            author:"vtstudent123",
            votes:7,
            voteState:1,
            comments:[
                ["timsands1", "Can you show us your dequeue method?"],
                ["vtstudent123", "I have not implemented that yet @timsands1"]
            ]
        },

        answers:[
            {
                accepted:true,
                author:"sandra_brown",
                body:[
                    ["text", "Your enqueue method should do this"],
                    ["image", "https://i.ytimg.com/vi/okr-XE8yTO8/maxresdefault.jpg"],
                    ["text",""]
                ],
                votes:12,
                voteState:0,
                comments:[
                    ["vtstudent123", "Awesome, thanks!"]
                ]
            },
            {
                accepted:false,
                author:"helloworld",
                body:[
                    ["text", "I GOT accepted losers"],
                    ["eq", "\\frac{10}{4x}"],
                    ["text", ""],
                    ["image", "https://i.pinimg.com/originals/50/ce/18/50ce18c363f5fbf65c851846b5f41532.jpg"],
                    ["text",""],
                    ["image", "https://i.pinimg.com/originals/50/ce/18/50ce18c363f5fbf65c851846b5f41532.jpg"],
                    ["text", ""]
                ],
                votes:4,
                voteState:0,
                comments:[
                    ["man23", "suck my balls"],
                ]
            },
            {
                accepted:false,
                author:"helloworld",
                body:[
                    ["text", "hhhhhhhhhhhhhhhhhello"],
                ],
                votes:14,
                voteState:0,
                comments:[
                    ["man23", "THIS IS A commENT"],
                    ["ihatepcp", "I will find where you live"],
                    ["pcpbythegallon", "ok thanks @man23"]
                ]
            },
        ]
    },
}

const Question = () => {

    const answerRef = React.useRef(null);

    const params = useParams(); //url
    const q_id = params.q;

    let out = {
        "question_id":q_id
    }

    React.useEffect(() => {
        let xhr = new XMLHttpRequest();
        let url = "/api/question"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                setRes(resp_json)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }, [])

    const [res, setRes] = React.useState(null);

    const renderBody = (ques, index) => {
        let bodyToRender = null;
        if (ques) {
            bodyToRender = res.question.body;
        }
        else {
            bodyToRender = res.answers[index].body;
        }

        let out = [];

        for (let i = 0; i < bodyToRender.length; i++) {
            if (bodyToRender[i][0] == "text") {
                out.push(
                    <Typography sx={{mb:1}}>{bodyToRender[i][1]}</Typography>
                )
            }
            else if (bodyToRender[i][0] == "eq") {
                out.push(
                    <Typography sx={{mb:1}} variant="body1">
                    <MathJax dynamic hideUntilTypeset={"first"} inline>{"\\(" + bodyToRender[i][1] + "\\)"}</MathJax>
                    </Typography>
                )
            }
            else if (bodyToRender[i][0] == "image") {
                out.push(
                    <Box sx={{mb:1}}>
                        <img style={{width:"65%"}} src={bodyToRender[i][1]} />
                    </Box>
                )
            }
        }
        return out;
    }

    const renderComments = (ques, index) => {
        let commentsToRender = null;
        if (ques) {
            commentsToRender = res.question.comments;
        }
        else {
            commentsToRender = res.answers[index].comments;
        }

        if (commentsToRender.length == 0) {
            return [];
        }

        let out = []
        for (let i = 0; i < commentsToRender.length; i++) {
            out.push(
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    {( commentsToRender[i][3] 
                                                                            // p_index will be the index, note that the question will set p_indx to -1 automatically
                        ? <IconButton onClick={() => handleDelete("comment", commentsToRender[i][4], i, index)} size='small'><DeleteIcon /></IconButton>
                        : <ArrowRightIcon />
                    )}
                    <Box>
                        <Typography variant="overline">{"@" + commentsToRender[i][0]} </Typography> 
                        <Typography variant="overline">{" - " + commentsToRender[i][2]} </Typography> 
                        <Typography sx={{mt:-1}} variant="body2">{commentsToRender[i][1]}</Typography>
                    </Box>
                </Box>
            )
        }
        
        return out;
    }

    const renderAnswers = () => {
        if (res == null) {
            return (
            <Box sx={{display:"flex", flexDirection:"column", mb:5}}>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{display:"flex", flexDirection:"column", mr:1, alignItems:"center"}}>
                        <IconButton size="large"><ArrowUpwardIcon sx={{bgcolor:"lightgrey", color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                        <IconButton size="large"><ArrowDownwardIcon sx={{bgcolor:"lightgrey", color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                    </Box>
                    <Card sx={{padding:1.5, boxSizing:"border-box", backgroundColor:"#F6F6F6", width:"100%"}}>
                        <Typography variant="h5"><Skeleton /></Typography>
                        <Typography variant="overline"><Skeleton /></Typography>
                        <Box sx={{mt:2, display:"flex", flexDirection:"column"}}>

                        </Box>
                        <Box>
                            <Skeleton />
                        </Box>
                    </Card>
                </Box>
                <Box sx={{ml:12, mt:2}}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Box>
            </Box>
            )
        }
        let answersToRender = res.answers;

        let out = []

        for (let i = 0; i < answersToRender.length; i++) {
            let voteUpState = answersToRender[i].voteState == 1;
            let voteDownState = answersToRender[i].voteState == -1;
            out.push (
                <Box key={i} sx={{display:"flex", flexDirection:"column", mb:5}}>
                    <Box sx={{display:"flex", flexDirection:"row"}}>
                        <Box sx={{display:"flex", flexDirection:"column", mr:1, alignItems:"center"}}>
                            <IconButton onClick={() => handleVote(false, i, 1)} size="large"><ArrowUpwardIcon sx={{bgcolor:(voteUpState ? "#630031" : "lightgrey"), color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                            <Typography>{answersToRender[i].votes}</Typography>
                            <IconButton onClick={() => handleVote(false, i, -1)} size="large"><ArrowDownwardIcon sx={{bgcolor:(voteDownState ? "#630031" : "lightgrey"), color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                            <BeenhereIcon sx={{mt:1, color:"green", display:(answersToRender[i].accepted ? "block" : "none")}} fontSize="large" />
                        </Box>
                        <Card sx={{padding:1.5, boxSizing:"border-box", backgroundColor:"#F6F6F6", width:"100%"}}>
                            <Typography variant="overline">@{answersToRender[i].author} - </Typography><Typography variant="overline">{answersToRender[i].timeAgo}</Typography>
                            <Box sx={{mt:2, display:"flex", flexDirection:"column", whiteSpace:"pre-line"}}>
                                {renderBody(false, i)}
                            </Box>

                            <Box sx={{display:"flex", justifyContent:"right"}}>
                            {(!answersToRender[i].op ? "" :
                                <Button onClick={() => handleDelete("answer",answersToRender[i].answerId , i, -1)} sx={{ml:"-5px"}} startIcon={<DeleteIcon />}>Delete</Button>
                            )}
                            </Box>
                            
                        </Card>
                    </Box>
                    <Box sx={{ml:12, mt:2, borderLeft:"2px solid"}}>
                        {renderComments(false, i)}
                        <Box sx={{display:"flex", flexDirection:"row", ml:2, mt:2, width:"100%"}}>
                                <TextField label="Reply" sx={{mr:1, maxWidth:275, width:"75%"}} size="small" />
                                <Button onClick={(e) => handleNewComment(e, false, i, answersToRender[i].answerId)}>Submit</Button>
                        </Box>
                    </Box>
                </Box>
            )
        }

        let sortArray = [...res.answers];
        for(let i = 0; i < sortArray.length; i++){ // https://www.geeksforgeeks.org/bubble-sort-algorithms-by-using-javascript/
            // Last i elements are already in place 
            for(let j = 0; j < ( sortArray.length - i - 1 ); j++){
               
              // Checking if the item at present iteration
              // is greater than the next iteration
                if(sortArray[j].votes < sortArray[j+1].votes){
                    
                    // If the condition is true then swap them
                    let temp = sortArray[j]
                    sortArray[j] = sortArray[j + 1]
                    sortArray[j+1] = temp

                    let tempOut = out[j]
                    out[j] = out[j + 1]
                    out[j+1] = tempOut
                }
            }
        }
        let acceptedIndex = 0;
        for (let i = 0; i < sortArray.length; i++) {
            if (sortArray[i].accepted) {
                acceptedIndex = i;
            }
        }

        if (acceptedIndex != 0) {
            let tempOut = out[acceptedIndex];
            out.splice(acceptedIndex, 1);
            out.splice(0, 0, tempOut); // adds accepted index to front
        }

        return out;
    }

    const [answerOpen, setAnswerOpen] = React.useState(false)
    const handleAnswer = () => {
        //let resCopy = structuredClone(res);
        //resCopy.answers.push()
        //setRes(resCopy)
        setAnswerOpen(true)
        const y = answerRef.current.getBoundingClientRect().top + window.pageYOffset - 100; // 100 is offset on top
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    const addNewAnswer = (inps) => {

        let s_inputs = [...inps];

        let formData = new FormData();

        for (let i = 0; i < s_inputs.length; i++) {
            if (s_inputs[i][0] == "image") {
                let tempName = "image" + i;
                let newFile = new File([s_inputs[i][1]], tempName, {type: s_inputs[i][1].type})
                formData.append("file", newFile);
                s_inputs[i][1] = tempName
            }
        }
        
        let out = {
            "body": s_inputs,
            "question_id": q_id,
            "csfr_token":window.localStorage.getItem("cToken")
        }

        let post_json = JSON.stringify(out)
        formData.append("json", post_json)

        let xhr = new XMLHttpRequest();
        let url = "/api/answer"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                
                let username = resp_json["cur_user"]
                let answer_id = resp_json["answer_id"]
                let body_with_imgurls = resp_json["body_with_imgurls"]


                for (let i = 0; i < s_inputs.length; i++) { // idk \_o_/
                    if (s_inputs[i][0] == "eq") {
                        s_inputs[i][1] = s_inputs[i][1]; // idk why but this works maybe a bug?
                    }
                }
        
                let resCopy = structuredClone(res);
                resCopy.answers.push(
                    {
                        accepted:false,
                        author:username,
                        body:body_with_imgurls,
                        votes:0,
                        comments:[],
                        timeAgo:"1 second ago",
                        answerId:answer_id,
                        op:true
                    }
                )
                setRes(resCopy)
        
                setAnswerOpen(false)

            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
            setLoading(false)
        }
        setLoading(true)
        xhr.send(formData)
        
    }

    const handleNewComment = (e, ques, index, id) => {
        let commToAppend = null;

        let username = "CODEDIN" // ONCEDONE: make work lol

        if (ques) {
            let val = e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].value
            let username = "none";
            let out = {
                "p_id":q_id,
                "p_type":"question",
                "content":val,
                "csfr_token":window.localStorage.getItem("cToken")
            }
            let xhr = new XMLHttpRequest();
            let url = "/api/comment"
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json")
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    let resp_json = JSON.parse(xhr.responseText);
                    window.localStorage.setItem("cToken", resp_json["csfr_token"])
                    username = resp_json["cur_user"]
                    let comment_id = resp_json["comment_id"]

                    commToAppend = [...res.question.comments]
                    commToAppend.push([username, val, "1 second ago", true, comment_id]) // gets value from input
                    let tempRes = structuredClone(res)
                    tempRes.question.comments = commToAppend

                    setRes(tempRes)
                }
                else if (xhr.readyState === 4 && xhr.status === 500) {
                    alert("Unknow server error!")
                }
                else if (xhr.readyState === 4 && xhr.status != 200) {
                    let resp_json = JSON.parse(xhr.responseText);
                    alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                }
                setLoading(false)
            }
            let post_json = JSON.stringify(out)
            setLoading(true)
            xhr.send(post_json)
        }
        else {
            let val = e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].value
            let username = "none";
            let out = {
                "p_id":id,
                "p_type":"answer",
                "content":val,
                "csfr_token":window.localStorage.getItem("cToken")
            }
            let xhr = new XMLHttpRequest();
            let url = "/api/comment"
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json")
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    let resp_json = JSON.parse(xhr.responseText);
                    username = resp_json["cur_user"]
                    window.localStorage.setItem("cToken", resp_json["csfr_token"])

                    commToAppend = [...res.answers[index].comments]
                    commToAppend.push([username, val, "1 second ago"]) // gets value from input
                    let tempRes = structuredClone(res)
                    tempRes.answers[index].comments = commToAppend

                    setRes(tempRes)
                }
                else if (xhr.readyState === 4 && xhr.status === 500) {
                    alert("Unknow server error!")
                }
                else if (xhr.readyState === 4 && xhr.status != 200) {
                    let resp_json = JSON.parse(xhr.responseText);
                    alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                }
                setLoading(false)
            }
            let post_json = JSON.stringify(out)
            setLoading(true)
            xhr.send(post_json)




            //commToAppend = [...res.answers[index].comments]
            //commToAppend.push([username, e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].value]) // gets value from input
            //let tempRes = structuredClone(res)
            //tempRes.answers[index].comments = commToAppend
            
            //setRes(tempRes)
        }

        e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].value = "" // clears
    }

    const handleVote = (ques, index, change) => {
        let resCopy = structuredClone(res)
        let p_type = null;
        let p_id = null;

        if (ques) {
            p_type = "question";
            p_id = q_id;
            if (res.question.voteState == -1 && change == -1) {
                resCopy.question.voteState = 0;
                resCopy.question.votes = resCopy.question.votes + 1;
            }
            else if (res.question.voteState == 1 && change == 1) {
                resCopy.question.voteState = 0;
                resCopy.question.votes = resCopy.question.votes - 1;
            }
            else if (res.question.voteState == -1 && change == 1) {
                resCopy.question.voteState = 1;
                resCopy.question.votes = resCopy.question.votes + 2;
            }
            else if (res.question.voteState == 1 && change == -1) {
                resCopy.question.voteState = -1;
                resCopy.question.votes = resCopy.question.votes - 2;
            }
            else if (res.question.voteState == 0 && change == 1) {
                resCopy.question.voteState = 1;
                resCopy.question.votes = resCopy.question.votes + 1;
            }
            else if (res.question.voteState == 0 && change == -1) {
                resCopy.question.voteState = -1;
                resCopy.question.votes = resCopy.question.votes - 1;
            }
        }
        else {
            p_type = "answer";
            p_id = res.answers[index].answerId;
            if (res.answers[index].voteState == -1 && change == -1) {
                resCopy.answers[index].voteState = 0;
                resCopy.answers[index].votes = resCopy.answers[index].votes + 1;
            }
            else if (res.answers[index].voteState == 1 && change == 1) {
                resCopy.answers[index].voteState = 0;
                resCopy.answers[index].votes = resCopy.answers[index].votes - 1;
            }
            else if (res.answers[index].voteState == -1 && change == 1) {
                resCopy.answers[index].voteState = 1;
                resCopy.answers[index].votes = resCopy.answers[index].votes + 2;
            }
            else if (res.answers[index].voteState == 1 && change == -1) {
                resCopy.answers[index].voteState = -1;
                resCopy.answers[index].votes = resCopy.answers[index].votes - 2;
            }
            else if (res.answers[index].voteState == 0 && change == 1) {
                resCopy.answers[index].voteState = 1;
                resCopy.answers[index].votes = resCopy.answers[index].votes + 1;
            }
            else if (res.answers[index].voteState == 0 && change == -1) {
                resCopy.answers[index].voteState = -1;
                resCopy.answers[index].votes = resCopy.answers[index].votes - 1;
            }
        }

        let out = {
            "voteState":change,
            "p_type":p_type,
            "p_id":p_id,
            "csfr_token":window.localStorage.getItem("cToken")
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/vote"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                window.localStorage.setItem("cToken", resp_json["csfr_token"])

                setRes(resCopy)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    // type is type, id is db id, index is index in array, p_index is only for comments
    const handleDelete = (type, id, index, p_index) => {
        if (window.confirm("You are about to delete your " + type + ". Click OK to continue.")) {
            if (type == "question") {
                let out = {
                    "id":id,
                    "type":"question",
                    "csfr_token":window.localStorage.getItem("cToken")
                }
                let xhr = new XMLHttpRequest();
                let url = "/api/delete"
                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json")
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        window.localStorage.setItem("cToken", resp_json["csfr_token"])
                        alert("Post deleted successfully")
                    }
                    else if (xhr.readyState === 4 && xhr.status === 500) {
                        alert("Unknow server error!")
                    }
                    else if (xhr.readyState === 4 && xhr.status != 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                    }
                    setLoading(false)
                }
                let post_json = JSON.stringify(out)
                setLoading(true)
                xhr.send(post_json)
            }
            else if (type == "comment") {
                let out = {
                    "id":id,
                    "type":"comment",
                    "csfr_token":window.localStorage.getItem("cToken")
                }
                let xhr = new XMLHttpRequest();
                let url = "/api/delete"
                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json")
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        window.localStorage.setItem("cToken", resp_json["csfr_token"])
                        if (p_index == -1) { // if question, else p_index would be >= 0
                            let commToPop = [...res.question.comments]
                            commToPop.splice(index, 1)
                            let tempRes = structuredClone(res)
                            tempRes.question.comments = commToPop
    
                            setRes(tempRes)
                        }
                        else {
                            let commToPop = [...res.answers[p_index].comments]
                            commToPop.splice(index, 1)
                            let tempRes = structuredClone(res)
                            tempRes.answers[p_index].comments = commToPop
    
                            setRes(tempRes)
                        }
                    }
                    else if (xhr.readyState === 4 && xhr.status === 500) {
                        alert("Unknow server error!")
                    }
                    else if (xhr.readyState === 4 && xhr.status != 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                    }
                    setLoading(false)
                }
                let post_json = JSON.stringify(out)
                setLoading(true)
                xhr.send(post_json)
            }
            else if (type == "answer") {
                let out = {
                    "id":id,
                    "type":"answer",
                    "csfr_token":window.localStorage.getItem("cToken")
                }
                let xhr = new XMLHttpRequest();
                let url = "/api/delete"
                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json")
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        window.localStorage.setItem("cToken", resp_json["csfr_token"])
                        
                        let tempRes = structuredClone(res)
                        tempRes.answers.splice(index, 1)
                        setRes(tempRes)
                    }
                    else if (xhr.readyState === 4 && xhr.status === 500) {
                        alert("Unknow server error!")
                    }
                    else if (xhr.readyState === 4 && xhr.status != 200) {
                        let resp_json = JSON.parse(xhr.responseText);
                        alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                    }
                    setLoading(false)
                }
                let post_json = JSON.stringify(out)
                setLoading(true)
                xhr.send(post_json)
            }
        }
    }

    const [loading, setLoading] = React.useState(false)
    
    return (
        <>
        {(!loading ? "" :
            <Box sx={{position:"fixed", top:"0px", left:"0px", width:"100%", height:"100%", zIndex:"100", bgcolor:"rgba(0,0,0,0.5)", backdropFilter:"blur(3px)", display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <link rel="stylesheet" href="/loader.css"></link>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </Box>
            </Box>
        )}

        <div id="content">
        <TopBar loginState="true"></TopBar>

        <Box sx={{margin:"auto", mt:5, display:"flex", width:"90%", maxWidth:"850px", flexDirection:"column"}}>

            {(res!=null ? 
            <Box sx={{display:"flex", flexDirection:"column", mb:5}}>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{display:"flex", flexDirection:"column", mr:1, alignItems:"center"}}>
                        <IconButton onClick={() => handleVote(true, -1, 1)} size="large"><ArrowUpwardIcon sx={{bgcolor:(res.question.voteState == 1 ? "#630031" : "lightgrey"), color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                        <Typography>{res.question.votes}</Typography>
                        <IconButton onClick={() => handleVote(true, -1, -1)} size="large"><ArrowDownwardIcon sx={{bgcolor:(res.question.voteState == -1 ? "#630031" : "lightgrey"), color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                    </Box>
                    <Card sx={{padding:1.5, boxSizing:"border-box", backgroundColor:"#F6F6F6", width:"100%"}}>
                        <Typography variant="overline">@{res.question.author} - </Typography><Typography variant="overline">{res.question.timeAgo}</Typography>
                        <Typography variant="h5">{res.question.title}</Typography>
                        
                        <Box sx={{mt:2, display:"flex", flexDirection:"column", whiteSpace:"pre-line"}}>
                            {renderBody(true, -1)}
                        </Box>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            <Button onClick={() => handleAnswer()} disableElevation sx={{mr:1}} variant="contained">Answer</Button>
                            {(!res.question.op ? "" :
                                <Button onClick={() => handleDelete("question", q_id, -1, -1)} sx={{ml:"-5px"}} startIcon={<DeleteIcon />}>Delete</Button>
                            )}
                        </Box>
                    </Card>
                </Box>
                <Box sx={{ml:12, mt:2, borderLeft:"2px solid"}}>
                    {renderComments(true, -1)}
                    <Box sx={{display:"flex", flexDirection:"row", ml:2, mt:2, width:"100%"}}>
                        <TextField label="Reply" sx={{mr:1, maxWidth:275, width:"75%"}} size="small" />
                        <Button onClick={(e) => handleNewComment(e, true, -1, -1)}>Submit</Button>
                    </Box>
                </Box>
            </Box>
            :
            <Box sx={{display:"flex", flexDirection:"column", mb:5}}>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{display:"flex", flexDirection:"column", mr:1, alignItems:"center"}}>
                        <IconButton size="large"><ArrowUpwardIcon sx={{bgcolor:"lightgrey", color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                        <IconButton size="large"><ArrowDownwardIcon sx={{bgcolor:"lightgrey", color:"white", borderRadius:1, transition:"0.2s"}} fontSize="inherit" /></IconButton>
                    </Box>
                    <Card sx={{padding:1.5, boxSizing:"border-box", backgroundColor:"#F6F6F6", width:"100%"}}>
                        <Typography variant="h5"><Skeleton /></Typography>
                        <Typography variant="overline"><Skeleton /></Typography>
                        <Box sx={{mt:2, display:"flex", flexDirection:"column"}}>

                        </Box>
                        <Box>
                            <Skeleton />
                        </Box>
                    </Card>
                </Box>
                <Box sx={{ml:12, mt:2}}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Box>
            </Box>
            )}

            <Divider sx={{mb:2}}><Typography variant="overline">Answers</Typography></Divider>

            <Box sx={{mb:5}} ref={answerRef}>
                <Card elevation={10} sx={{padding:3, bgcolor:"#F6F6F6", display:(answerOpen ? "block" : "none")}}>
                    
                    <Box><QuestionAnswerInput addNewAnswer={(inps) => addNewAnswer(inps)} /></Box>

                </Card>
            </Box>

            {renderAnswers()}

        </Box>

        <Box sx={{mb:10}}></Box>

        </div>
        <Footer></Footer>
        </>
    );
}
 
export default Question;