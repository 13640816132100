import * as React from 'react';
import {useState, useRef} from 'react';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, TextField, Step, StepButton, Stepper} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import SignupInputs from './SignupInputs';

const steps = ['Basic Info', 'Add Courses', 'Finish'];

let data = {
    username: [null, false],
    email: [null, false],
    password: [null, false],
    confirmPassword: [null, false],
    dob: [null, false],

    courses: [],

    pptosagree: null
}

const Signup = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['login_state_cont']);

    const childRef = useRef();

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };


    const [canClickNext, setCanClickNext] = React.useState(false);
    const validFunc = (d, stage) => { // called by child when all data is valid
        if (stage == 1) {
            data.username = d.username;
            data.email = d.email;
            data.password = d.password;
            data.confirmPassword = d.confirmPassword;
            data.dob = d.dob;
        }
        else if (stage == 2) {
            data.courses = d.courses;
        }

        handleComplete();
        setCanClickNext(true);
    }


    const getChildData = (data) => { // called by parent
        let test = childRef.current.getData("hi");
        alert(test);
    }

    React.useEffect(() => {
        if (cookies["login_state_cour"] == "true") {
            setRedirect("/home")
        }
    }, [])

    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }


    return (
        <>
        {renderNavigate()}
        <Box mt="15vh" sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <Box sx={{display:"flex", alignItems:"center"}}>
                <Box
                component="img"
                sx={{
                width: 55,
                cursor: "pointer",
                mr: 2
                }}
                alt="Courseology logo"
                src="logo.png"
                />
                <Typography variant="h4" sx={{height:"100%", poition:"relative"}}>Courseology - Sign Up</Typography>
            </Box>

            <Box sx={{mt:3}}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                        </StepButton>
                    </Step>
                    ))}
                </Stepper>
                <div>
                    {allStepsCompleted() ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                    ) : (
                    <React.Fragment>



                        <SignupInputs storedData={data} validFunc={validFunc} ref={childRef} stage={activeStep + 1}></SignupInputs>



                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button 
                            disabled={!canClickNext || activeStep === 2}
                            onClick={handleNext} 
                            sx={{ mr: 1 }}>
                            Next
                        </Button>
                        
                        </Box>
                    </React.Fragment>
                    )}
                </div>
            </Box>
        </Box>
        </>
    );
}
 
export default Signup;