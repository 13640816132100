import * as React from 'react';
import { Typography, Box, AppBar, Toolbar, Button, Divider} from '@mui/material';

const Footer = () => {
    return (
        <>
        <Divider sx={{mb:2}}></Divider>
        <Typography variant="body2" align="center">Copyright © 2022 - Courseology - Courseology.net</Typography>
        <Typography variant="body2" align="center" sx={{mb:2}}>A William Burriss Production</Typography>
        </>
    );
}
 
export default Footer;