import * as React from 'react';
import {Navigate, Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, InputLabel, FormControl, Paper, IconButton} from '@mui/material';
import { MathJax } from 'better-react-mathjax';

import FunctionsIcon from '@mui/icons-material/Functions';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';



const HomeTabUpload = () => {

    let [userCourses, setUserCourses] = React.useState([])

    React.useEffect(() => {
        let xhr = new XMLHttpRequest();
        let url = "/api/courses"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                let resp_courses = resp_json["courses"]
                let temp = []
                for (let i = 0; i < resp_courses.length; i++) {
                    temp.push(
                        <MenuItem key={i} value={resp_courses[i]}>{resp_courses[i]}</MenuItem>
                    )
                }
                setUserCourses(temp)
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        xhr.send()
    }, [])

    const [curfile, setCurfile] = React.useState(null)

    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
        } else if (e.type === "dragleave") {
        setDragActive(false);
        }
    };
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    };
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };
    const acceptedFiles = ["pdf", "docx", "doc", "ppt", "pptx"]
    const handleFiles = (files) => {
        if (acceptedFiles.indexOf(files[0].name.split(".")[files[0].name.split(".").length -1]) < 0) {
            alert("File type not supported \nPlease upload " + acceptedFiles.join(", "))
        }
        else {
            setCurfile(files[0])
        }
    }

    const [equations, setEquations] = React.useState([])
    const [course, setCourse] = React.useState('');
    const handleCourseChange = (event) => {
        setCourse(event.target.value);
    };

    const [addOpen, setAddOpen] = React.useState(false)
    const handleOpenAddEquation = () => {
        setAddOpen(true)
    }
    const handleCloseAddEquation = () => {
        setAddOpen(false);
    }
    let bug_replaces = [ // fixes discrepancies between mathjax and live math input
        ["\\differentialD", "d"]
    ]
    const handleAddEquation = (e) => {
        let newVal = e.target.parentNode.parentNode.childNodes[0].value; // gets value of field
        for (let i = 0; i < bug_replaces.length; i++) {
            newVal = newVal.replaceAll(bug_replaces[i][0],bug_replaces[i][1])
        }
        let temp = [...equations];
        temp.push(newVal);
        setEquations(temp);
        handleCloseAddEquation();

        e.target.parentNode.parentNode.childNodes[0].value = null; // clears field
    }
    const handleDeleteEquation = (i) => {
        let temp = [...equations];
        temp.splice(i, 1);
        setEquations(temp);
    }

    const renderEquations = () => {
        let temp = [...equations];
        let out = []
        for (let i = 0; i < temp.length; i++) {
            let val = temp[i];
            val = "\\(" + val + "\\)"
            out.push(
                <Box
                sx={{minWidth:"100%", margin:"auto", borderBottom:"1px solid grey", display:"flex", justifyContent:"space-between", alignItems:"center", mt:1}}
                key={i}
                >
                    <MathJax dynamic hideUntilTypeset={"first"} inline>{val}</MathJax>
                    <IconButton sx={{ml:2}} onClick={() => handleDeleteEquation(i)}><DeleteIcon/></IconButton>
                </Box>
            )
        }
        return out;
    }

    const titleRef = React.useRef(null)
    const descRef = React.useRef(null)
    const courseRef = React.useRef(null)
    const courseFieldRef = React.useRef(null)
    const courseNumberRef =  React.useRef(null)
    const yearRef = React.useRef(null)
    const semRef = React.useRef(null)

    const handleSubmit = () => {
        if (curfile == null) {
            alert("Please select a file")
            return;
        }
        if (titleRef.current.value.length == 0) {
            alert("Please enter a title")
            return;
        }
        if (courseRef.current.value.length == 0) {
            alert("Please enter a course")
            return;
        }
        if (courseRef.current.value == "other" && 
            courseFieldRef.current.value.length == 0 &&
            courseNumberRef.current.value.length == 0
            ) {
            alert("Please enter a valid Course Field and Course Number")
            return;
        }
        let course;
        if (courseRef.current.value == "other") {
            course = courseFieldRef.current.value + courseNumberRef.current.value
        }
        else {
            course = courseRef.current.value
        }

        let title = titleRef.current.value;
        let desc = descRef.current.value;
        let year = yearRef.current.value;
        let sem = semRef.current.value;

        let out = {
            "title": title,
            "desc": desc,
            "course": course,
            "year": year,
            "sem": sem,
            "equations": equations,
            "csfr_token": window.localStorage.getItem("cToken")
        }
        let post_json = JSON.stringify(out)

        let formData = new FormData();

        formData.append("file", curfile)
        formData.append("json", post_json)

        let xhr = new XMLHttpRequest();
        let url = "/api/upload"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                
                setRedirect(resp_json["redirect"])
                setLoading(false)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                setLoading(false)
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                setLoading(false)
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        setLoading(true)
        xhr.send(formData)
    }
    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }
    const [loading, setLoading] = React.useState(false)

    return (
        <>
        {(!loading ? "" :
            <Box sx={{position:"fixed", top:"0px", left:"0px", width:"100%", height:"100%", zIndex:"100", bgcolor:"rgba(0,0,0,0.5)", backdropFilter:"blur(3px)", display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <link rel="stylesheet" href="/loader.css"></link>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </Box>
            </Box>
        )}
        {renderNavigate()}
        <Box>
            {( curfile == null ?
            <Box sx={{display:"flex"}}>
                <form style={{height:300, width:"80%", maxWidth:400, textAlign:"center", margin:"auto", border:"2px dashed", borderRadius:"10px", display:"flex", alignItems:"center", alignContent:"center", marginTop:"35px"}} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <Box width="100%">
                        <input accept="application/pdf, .docx, .doc, .ppt, .pptx" style={{display:"none"}} ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                            <div>
                            <Box>
                                <Typography variant="button">Drag and drop your file here or</Typography>
                            </Box>
                            <Button sx={{mt:2}} variant="outlined" className="upload-button" onClick={onButtonClick}>Upload a file</Button>
                            <Box>
                                <Typography variant="overline">PDF, DOCX, PPTX, DOC, PPT</Typography>
                            </Box>
                            </div> 
                        </label>
                        { dragActive && <div style={{position:"absolute", width:"100%", height:"100%", top:"0px", left:"0px"}} id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                    </Box>
                </form>
            </Box>
            :
            <Box sx={{display:"flex"}}>
                <Box sx={{position:"relative", width:"80%", maxWidth:400, position:"relative", margin:"auto", mt:5}}>
                    <Typography variant='h6'>Filename: {curfile.name}</Typography>
                    <Typography variant='subtitle2'>Filesize: {Math.floor(curfile.size / 1000)} kb</Typography>
                    <Typography variant='subtitle2'>Filetype: {curfile.type}</Typography>
                    <Typography variant='subtitle2'>Last modified: {curfile.lastModifiedDate.toString()}</Typography>
                    <Button onClick={() => setCurfile(null)} startIcon={<DeleteIcon />} sx={{mt:1, mb:2}}>Choose another File</Button>
                </Box>
            </Box>
            )}

            <Box sx={{position:"relative", width:"80%", maxWidth:400, position:"relative", left:"50%", transform:"translateX(-50%)"}}>
                {renderEquations()}
                <Box
                    sx={{margin:"auto", mt:2, display:"flex", alignItems:"center"}}
                >
                    <Button onClick={() => handleOpenAddEquation()} sx={{mr:2}} startIcon={<FunctionsIcon />} variant="outlined">Transcribe Equations</Button>
                </Box>
                <Typography color="grey" variant="caption">This makes our search feature more acurate</Typography>
                <Box></Box>
                <Paper elevation={5}
                sx={{width:"200%", maxWidth:"95vw", margin:"auto", mt:2, display:"flex", alignItems:"center",
                    padding:2,
                    boxSizing:"border-box",
                    borderBottom: "2px solid grey" , 
                    position:"absolute",
                    top:"-25px",
                    left:"50%",
                    transform:"translateX(-50%)",
                    display:(addOpen ? "flex" : "none"),
                    justifyContent:"space-between",
                }}
                onMouseOver={() => document.getElementById("mathfield").focus()}
                >
                    <math-field virtual-keyboard-mode="manual" onMouseOver={() => document.getElementById("mathfield").focus()} id="mathfield" style={{padding:"5px", maxWidth:"300px", minWidth:"200px", border:"2px solid", borderRadius:"5px"}}></math-field>
                    <Box>
                        <Button onClick={() => handleCloseAddEquation()} sx={{ml:2}}>Close</Button>
                        <Button onClick={(e) => handleAddEquation(e)} sx={{ml:2}} startIcon={<AddIcon />} variant="contained">Add Equation</Button>
                    </Box>
                </Paper>
            </Box>

            <Box sx={{display:"flex", width:"80%", maxWidth:400, mt:5, position:"relative", left:"50%", transform:"translateX(-50%)", display:"flex", flexDirection:"column"}}>
                <TextField inputRef={titleRef} required label="Title" />
                <TextField inputRef={descRef} label="Description" minRows={3} maxRows={6} multiline size='small' sx={{mt:3}} />
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", mt:3}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Course *</InputLabel>
                        <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={course}
                        label="course"
                        onChange={handleCourseChange}
                        inputRef={courseRef}
                        >
                        {userCourses}
                        <MenuItem value={"other"}><b>Other Course</b></MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{display:(course == "other" ? "flex" : "none"), flexDirection:"row", justifyContent:"space-between", mt:3}}>
                    <TextField inputRef={courseFieldRef} required sx={{width:"47%"}} label="Course Field" />
                    <TextField inputRef={courseNumberRef} required sx={{width:"47%"}} label="Course Number" />
                </Box>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", mt:3}}>
                    <TextField type="number" inputRef={yearRef} sx={{width:"47%"}} label="Year" />
                    <TextField inputRef={semRef} sx={{width:"47%"}} label="Semester" />
                </Box>

                <Button
                sx={{minWidth:"200px", margin:"auto", mt:5}}
                variant="contained"
                size="large"
                onClick={() => handleSubmit()}
                >Submit</Button>
            </Box>
        </Box>

        <Box sx={{mb:10}}></Box>
        </>
    );
}
 
export default HomeTabUpload;