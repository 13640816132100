import React, { useContext, useState } from 'react';

const CSFRTokenContext = React.createContext()
const CSFRTokenUpdateContext = React.createContext()

export function useCSFRToken() {
    return useContext(CSFRTokenContext)
}

export function useCSFRTokenUpdate() {
    return useContext(CSFRTokenUpdateContext)
}

/*
No longer user, using localstorage
*/
export function CSFRTokenProvider({ children }) {
    const [CSFRToken, setCSFRToken] = useState("none")

    return (
        <CSFRTokenContext.Provider value={CSFRToken}>
            <CSFRTokenUpdateContext.Provider value={setCSFRToken}>
                {children}
            </CSFRTokenUpdateContext.Provider>
        </CSFRTokenContext.Provider>
    )
}