import * as React from 'react';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Autocomplete, Typography, Box, AppBar, Toolbar, Button, Divider, TextField, Skeleton} from '@mui/material';
import { Navigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCookies } from 'react-cookie';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';

const styles = ["APA (American Psychological Association)", "MLA (Modern Language Association)", "Chicago (Turabian)", "IEEE (Institute of Electrical and Electronics Engineers)", "CSE (Council of Science Editors)", "AAA (American Anthropological Association)", "APSA (American Political Science Association)", "ASA (American Sociological Association)", "ACS (American Chemical Society)", "AMA (American Medical Association)", "NLM (National Library of Medicine)", "Vancouver", "Bluebook", "OSCOLA", "Harvard"]

const CiteViewer = () => {

    const recaptchaRef = React.useRef(null);

    const [cookies, setCookie, removeCookie] = useCookies(['login_state_cont']);

    const [citations, setCitations] = React.useState(null)

    const [style, setStyle] = React.useState(null)

    const initCall = async () => {
        let out = {
            "recaptcha_token": await recaptchaRef.current.executeAsync()
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/createcites"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                recaptchaRef.current.reset()

                if (resp_json["style"] == undefined) {
                    setStyle(styles[0])
                }
                else {
                    setStyle(resp_json["style"])
                }

                setCitations(resp_json["html"])
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    React.useEffect(() => {
        initCall()
    }, [citations])


    const handleNewCitation = () => {
        if (cookies["login_state_cour"] == "true") {
            setRedirect("/home/cite")
        }
    }

    const handleClear = () => {
        if (window.confirm("You are about to clear your citations. Click OK to continue.")) {
            let out = {
                
            }

            let xhr = new XMLHttpRequest();
            let url = "/api/clearcites"
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json")
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    let resp_json = JSON.parse(xhr.responseText);

                    setCitations("")
                }
                else if (xhr.readyState === 4 && xhr.status === 500) {
                    alert("Unknow server error!")
                }
                else if (xhr.readyState === 4 && xhr.status != 200) {
                    let resp_json = JSON.parse(xhr.responseText);
                    alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                }
            }
            let post_json = JSON.stringify(out)
            xhr.send(post_json)
        }
    }

    const handleChangeStyle = (newVal) => {

        setStyle(newVal)


        let out = {
            "style": newVal
        }
        let xhr = new XMLHttpRequest();
        let url = "/api/changecitestyle"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                setCitations(null)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const selectText = (container) => {
        if (document.selection) { // IE
            var range = document.body.createTextRange();
            range.moveToElementText(container);
            range.select();
        } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(container);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
    }

    const copyToClipboard = str => { // https://www.30secondsofcode.org/articles/s/copy-text-to-clipboard-with-javascript
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const handleCopy = () => {
        let frame = document.getElementById("citationIframe")
        let divs = frame.contentWindow.document.getElementById("refs")
        let text = divs.textContent
        copyToClipboard(text)
    }


    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    return (
        <>
        {renderNavigate()}
        <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LcIz3EkAAAAAEPhHJp2EoHtqV_Ik4kT4K38WOiq"
        />
        <div id="content">
            <TopBar loginState="false"></TopBar>

            {( citations == null ?
                <>
                <Box sx={{mt:10}}>
                    <Typography variant='h4' sx={{textAlign:"center"}}>Loading your citations</Typography>
                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                        <link rel="stylesheet" href="/loader.css"></link>
                        <div style={{filter:"invert(1)", transform:"scale(0.7)"}} className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <Box sx={{ml:2, mr:2, maxWidth:600, bgcolor:"#F6F6F6", width:"100%", padding:2, borderRadius:1}}>
                        <Box sx={{padding:1, bgcolor:"white", borderRadius:1}}>
                            <Skeleton sx={{mb:1}} variant="rounded" height={60} />
                            <Skeleton sx={{mb:1}} variant="rounded" height={60} />
                            <Skeleton variant="rounded" height={60} />
                        </Box>
                        <Box sx={{display:"flex", flexDirection:"row", mt:3}}>
                            <Button startIcon={<ContentCopyIcon />} sx={{mr:1}}>Copy</Button>
                            <Button onClick={handleClear} startIcon={<DeleteIcon />} sx={{mr:1}}>Clear</Button>
                            <Button startIcon={<DownloadIcon />} sx={{mr:1}}>Export</Button>
                        </Box>
                    </Box>
                    <Button variant="contained" sx={{mt:5}}>Create another citation</Button>
                </Box>

                </>
                :
                <>
                <Box sx={{mt:10}}>
                    <Typography variant='h4' sx={{textAlign:"center", mb:4}}>Your citations</Typography>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:5, mt:3}}>
                    <Typography variant='body1'>Change Citation Style</Typography>
                    <Autocomplete 
                        defaultValue={style || {}}
                        clearOnEscape
                        sx={{ width: 300 }}
                        options={styles}
                        onChange={(e, newVal) => handleChangeStyle(newVal)}
                        renderInput={(params) => (
                            <TextField {...params} label="Citation Style" variant="filled" />
                        )}
                    />
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <Box sx={{ml:2, mr:2, maxWidth:600, bgcolor:"#F6F6F6", width:"100%", padding:2, borderRadius:1}}>
                        <Box sx={{padding:1, bgcolor:"white", borderRadius:1}}>
                            <iframe id='citationIframe' style={{width:"100%", height:300, border:"0px solid"}} srcDoc={citations}></iframe>
                        </Box>
                        <Box sx={{display:"flex", flexDirection:"row", mt:3}}>
                            <Button onClick={handleCopy} startIcon={<ContentCopyIcon />} sx={{mr:1}}>Copy</Button>
                            <Button onClick={handleClear} startIcon={<DeleteIcon />} sx={{mr:1}}>Clear</Button>
                            <Button disabled startIcon={<DownloadIcon />} sx={{mr:1}}>Export</Button>
                        </Box>
                    </Box>
                    <Button onClick={handleNewCitation} variant="contained" sx={{mt:5}}>Create another citation</Button>
                </Box>
                </>
            )}

        </div>
        <Footer></Footer>
        </>
    );
}
 
export default CiteViewer;