import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Skeleton, Select, MenuItem, TextField, InputLabel, FormControl, Card, CardContent, Icon, CardMedia} from '@mui/material';
import { MathJax } from 'better-react-mathjax';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const PostRenderer = (props) => {

    // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
    function stringToColor(stringInput) {
        let stringUniqueHash = [...stringInput].reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
    }

    let curPosts = props.posts

    const renderCards = () => {
        let out = []
        for (let i = 0; i < curPosts.length; i++) {
            let curPost = curPosts[i]
            if (curPost[0] == "question") {
                out.push(
                    <Button sx={{mb: 2, width:"90%", maxWidth:900, position:"relative", left:"50%", transform:"translateX(-50%)", textTransform: "none"}} component={Link} to={"/question/" + curPost[2]}>
                    <Card sx={{width:"100%", bgcolor:"#F6F6F6"}}>
                        <CardContent>
                        <Typography variant="h5">{curPost[5]}</Typography>
                            <Typography variant="overline">@{curPost[6]}</Typography>
                            <Typography sx={{mt:2}} variant="body2">
                                <MathJax dynamic hideUntilTypeset={"first"} inline>{curPost[4]}</MathJax>
                            </Typography>
                            {( curPost[3] != "none"
                            ?
                            <Box
                                component="img"
                                src={curPost[3]}
                                sx={{mt:2, height:50, borderRadius:2}}
                            />
                            :
                            ""
                            )}
                            <Box sx={{mb:-1.5, mt:2, display:"flex"}}>
                                <Typography sx={{mr:2}} color="primary" variant="subtitle2"> <Icon color="primary" sx={{position:"relative", top:4}} fontSize="small" component={ArrowUpwardIcon} /> {curPost[1]}</Typography>
                                <Chip sx={{bgcolor:stringToColor(curPost[7]), color:"white"}} label={curPost[7]} />
                            </Box>
                        </CardContent>
                    </Card>
                    </Button>
                )
            }
            else if (curPost[0] == "file") {
                out.push(
                    <Button sx={{mb: 2, width:"90%", maxWidth:900, position:"relative", left:"50%", transform:"translateX(-50%)", textTransform: "none"}} component={Link} to={"/file/" + curPost[2]}>
                    <Card sx={{width:"100%", bgcolor:"#F6F6F6"}}>
                        <CardContent>
                            <CardMedia 
                                sx={{mb:1}}
                                component="img"
                                height="350"
                                image={curPost[3]}
                            />
                            <Typography variant="h5">{curPost[5]}</Typography>
                            <Typography variant="overline">@{curPost[6]}</Typography>
                            <Typography sx={{mt:2}} variant="body2">
                                <MathJax dynamic hideUntilTypeset={"first"} inline>{curPost[4]}</MathJax>
                            </Typography>
                            <Box sx={{mb:-1.5, mt:2, display:"flex"}}>
                                <Typography sx={{mr:2}} color="primary" variant="subtitle2"> <Icon color="primary" sx={{position:"relative", top:4}} fontSize="small" component={ArrowUpwardIcon} /> {curPost[1]}</Typography>
                                <Chip sx={{bgcolor:stringToColor(curPost[7]), color:"white"}} label={curPost[7]} />
                            </Box>
                        </CardContent>
                    </Card>
                    </Button>
                )
            }
        }
        return out;
    }

    return (
        <>
            {renderCards()}
        </>
    );
}
 
export default PostRenderer;