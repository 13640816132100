import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel, NativeSelect} from '@mui/material';

const CDate = (props) => {

    const defaultYear = () => {
        if (props["dataKey"] == "date") {
            if (props["data"]["year"]) {
                return props["data"]["year"]
            }
        }
        if (props["data"][props["dataKey"]]) {
            return props["data"][props["dataKey"]][0]
        }
        return ""
    }

    const defaultMonth = () => {
        if (props["data"][props["dataKey"]]) {
            return props["data"][props["dataKey"]][1]
        }
        return "__null__"
    }

    const defaultDay = () => {
        if (props["data"][props["dataKey"]]) {
            return props["data"][props["dataKey"]][2]
        }
        return ""
    }

    const [year, setYear] = React.useState(defaultYear());
    const [month, setMonth] = React.useState(defaultMonth());
    const [day, setDay] = React.useState(defaultDay());

    const yearRef = React.useRef(null);
    const dayRef = React.useRef(null);

    const handleChangeMonth = (event) => {
        let tempMonth = event.target.value
        setMonth(tempMonth);
        handleChange(tempMonth);
    };

    const handleChange = (inMonth) => {
        let monthCur = month;
        
        if (inMonth) {
            monthCur = inMonth
        }
        props["updateFunc"](props["dataKey"],[yearRef.current.value, monthCur, dayRef.current.value])
    }

    return (
        <>
        <Box sx={{maxWidth:500}}>
            <Typography sx={{mb:1}} variant='h6'>{props["label"]}</Typography>
            <TextField defaultValue={year} inputProps={{className: props["dataKey"] + "Year"}} onChange={() => handleChange()} inputRef={yearRef} sx={{width:100, mr:1}} type="number" size="small" label="Year" />
            <FormControl sx={{mr:1}}>
                <InputLabel>Month</InputLabel>
                <Select
                    value={month}
                    label="Month"
                    onChange={handleChangeMonth}
                    size="small"
                >
                    <MenuItem value={'__null__'}><i>No month</i></MenuItem>
                    <MenuItem value={'1'}>January - 1</MenuItem>
                    <MenuItem value={'2'}>February - 2</MenuItem>
                    <MenuItem value={'3'}>March - 3</MenuItem>
                    <MenuItem value={'4'}>April - 4</MenuItem>
                    <MenuItem value={'5'}>May - 5</MenuItem>
                    <MenuItem value={'6'}>June - 6</MenuItem>
                    <MenuItem value={'7'}>July - 7</MenuItem>
                    <MenuItem value={'8'}>August - 8</MenuItem>
                    <MenuItem value={'9'}>September - 9</MenuItem>
                    <MenuItem value={'10'}>October - 10</MenuItem>
                    <MenuItem value={'11'}>November - 11</MenuItem>
                    <MenuItem value={'12'}>December - 12</MenuItem>
                </Select>
            </FormControl>
            <TextField defaultValue={day} inputProps={{className: props["dataKey"] + "Day"}} onChange={() => handleChange()} inputRef={dayRef} sx={{width:100}} type="number" size="small" label="Day" />
        </Box>
        </>
    );
}
 
export default CDate;