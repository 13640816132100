import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel, NativeSelect} from '@mui/material';

const CText = (props) => {
    
    const handleChange = (e) => {
        props["updateFunc"](props["dataKey"],e.target.value)
    }

    return (
        <>
        <Box sx={{maxWidth:500}}>
            <Typography sx={{mb:1}} variant='h6'>{props["label"]}</Typography>
            <TextField defaultValue={props["data"][props["dataKey"]]} onChange={(e) => handleChange(e)} fullWidth size="medium" label={props["label"]} />
        </Box>
        </>
    );
}
 
export default CText;