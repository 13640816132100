import * as React from 'react';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, TextField} from '@mui/material';
import { Navigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCookies } from 'react-cookie';

const Login = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['login_state_cont']);

    let emailRef = React.useRef(null);
    let passwordRef = React.useRef(null);

    const handleSubmit = async () => {
        let s_email = emailRef.current.value;
        let s_password = passwordRef.current.value;

        let out = {
            "email": s_email,
            "password": s_password,
            "recaptcha_token": await recaptchaRef.current.executeAsync()
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/login"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                window.localStorage.setItem("cToken", resp_json["csfr_token"])

                setCookie("login_state_cour", "true");
                
                setRedirect(resp_json["redirect"])
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    React.useEffect(() => {
        if (cookies["login_state_cour"] == "true") {
            setRedirect("/home")
        }
    }, [])

    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    const recaptchaRef = React.useRef(null);

    return (
        <>
        {renderNavigate()}

        <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LcIz3EkAAAAAEPhHJp2EoHtqV_Ik4kT4K38WOiq"
        />

        <Box mt="25vh" sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <Box sx={{display:"flex", alignItems:"center"}}>
                <Box
                component="img"
                sx={{
                width: 55,
                cursor: "pointer",
                mr: 2
                }}
                alt="Courseology logo"
                src="logo.png"
                />
                <Typography variant="h4" sx={{height:"100%", poition:"relative"}}>Courseology - Login</Typography>
            </Box>
            <TextField inputRef={emailRef} sx={{maxWidth:"250px"}} label="Email" variant="standard" ></TextField>
            <TextField inputRef={passwordRef} sx={{maxWidth:"250px", mt:1}} label="Password" type="password" variant="standard" ></TextField>
            <Button onClick={() => handleSubmit()} sx={{mt:3, width:"250px"}} variant="contained">Login</Button>
            <Button component={Link} to="/forgotpassword" size="small" sx={{mt:3}} >Forgot Password</Button>
            <Button component={Link} to="/signup" size="small" sx={{mt:1}} >Sign up</Button>
        </Box>
        </>
    );
}
 
export default Login;