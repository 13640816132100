import * as React from 'react';
import {useState, forwardRef, useImperativeHandle} from 'react';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, TextField, Paper, Checkbox} from '@mui/material';
import { Navigate } from 'react-router-dom';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

let data = {
    username: [null, false],
    email: [null, false],
    password: [null, false],
    confirmPassword: [null, false],
    dob: [null, false],

    courses: [],

    pptosagree: null
}

const SignupInputs = forwardRef((props, ref) => {


    if (props.storedData.email[0] != null) {
        data.username = props.storedData.username;
        data.email = props.storedData.email;
        data.password = props.storedData.password;
        data.confirmPassword = props.storedData.confirmPassword;
        data.dob = props.storedData.dob;
    }

    const [usernameState, setUsername] = React.useState(data.username[0]);
    const [emailState, setEmail] = React.useState(data.email[0]);
    const [passwordState, setPassword] = React.useState(data.password[0]);
    const [confirmPasswordState, setConfirmPassword] = React.useState(data.confirmPassword[0]);
    const [dobState, setDob] = React.useState(data.dob[0]);


    useImperativeHandle(ref, ()=> ({
        getData(data) {
            return data;
        }
    }))

    const [value, setValue] = React.useState(data.dob[0]);

    const handleChange = (newValue) => { // date picker code https://mui.com/x/react-date-pickers/getting-started/
        setValue(newValue);
        handleTextInput("dob", newValue);
    };
    
    const [validUsername, setValidUsername] = React.useState(true);
    const [validEmail, setValidEmail] = React.useState(true);
    const [validPassword, setValidPassword] = React.useState(true);
    const [validConfirmPassword, setValidConfirmPassword] = React.useState(true);
    const [validDob, setValidDob] = React.useState(true);
    const handleTextInput = (input, value) => {
        if (input == "username") {
            let validChars = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm1234567890_"
            let new_username = value.replace(/[^a-zA-Z0-9_]/g, "")
            setValidUsername(true);
            if (new_username.length < 5) {
                setValidUsername(false);
            }
            setUsername(new_username)
            data.username[0] = value;
            data.username[1] = true;
        }
        else if (input == "email") {
            if (value.endsWith("@vt.edu")) {
                setValidEmail(true);
                data.email[1] = true;
            }
            else {
                setValidEmail(false);
                data.email[1] = false;
            }
            setEmail(value);
            data.email[0] = value;
        }
        else if (input == "password") {
            if (value.length >= 8) {
                setValidPassword(true)
                data.password[1] = true;
            }
            else {
                setValidPassword(false)
                data.password[1] = false;
            }
            setPassword(value)
            data.password[0] = value;
        }
        else if (input == "confirmPassword") {
            if (value == data.password[0]) {
                setValidConfirmPassword(true)
                data.confirmPassword[1] = true;
            }
            else {
                setValidConfirmPassword(false)
                data.confirmPassword[1] = false;
            }
            setConfirmPassword(value)
            data.confirmPassword[0] = value;
        }
        else if (input == "dob") {
            let parsedDate = 0;
            parsedDate = parsedDate + (dayjs(value, 'YYYY-MM-DD').year() * 10000);
            parsedDate = parsedDate + (dayjs(value, 'YYYY-MM-DD').month() * 100);
            parsedDate = parsedDate + (dayjs(value, 'YYYY-MM-DD').date());

            let todaysDate = 0;
            todaysDate = todaysDate + (dayjs().year() * 10000);
            todaysDate = todaysDate + (dayjs().month() * 100);
            todaysDate = todaysDate + (dayjs().date());

            if (todaysDate - parsedDate > 13 * 10000) {
                setValidDob(true);
                data.dob[1] = true;
            }
            else {
                setValidDob(false);
                data.dob[1] = false;
            }
            setDob(value)
            data.dob[0] = parsedDate + 100; // date had month as 0-11 updates to 1-12 given yyyymmdd
        }

        if (data.username[1] && data.email[1] && data.password[1] && data.confirmPassword[1] && data.dob[1]) {
            props.validFunc(data, 1);
        }
    }

    //----------------------------------------------------

    if (props.storedData.courses.length != 0) {
        data.courses = props.storedData.courses;
    }

    const renderClasses = () => {
        let out = [];
        for (let i = 0; i < data.courses.length; i++) {
            out.push(
                <Box key={i} sx={{mb:1}}>
                    <Typography sx={{mr:1, display:"inline-block", width:125, p:1, backgroundColor:"lightGrey"}}>{data.courses[i][0]}</Typography>
                    <Typography sx={{display:"inline-block", width:125, p:1, backgroundColor:"lightGrey"}}>{data.courses[i][1]}</Typography>
                    <Button onClick={() => handleDeleteClass(i)} sx={{ml:1}}>Delete</Button>
                </Box>
            );
        }
        return out;
    }


    const [classes, setClasses] = React.useState(data.courses);

    const [curInput1, setCurInput1] = React.useState(""); // course field ie MATH
    const [curInput2, setCurInput2] = React.useState(""); // course number ie 101

    const handleAddClass = () => {
        let temp = classes
        if (curInput1 == "" && curInput2 == "") {
            return
        }
        temp.push([curInput1.toUpperCase(), curInput2.toUpperCase()])
        setClasses(temp)

        setCurInput1("")
        setCurInput2("")

        props.validFunc(data, 2);
    }

    const handleDeleteClass = (index) => {
        let temp = [...data.courses]
        temp.splice(index, 1);
        setClasses(temp)
        data.courses = temp;

        props.validFunc(data, 2);
    }

    const handleClassText = (fon, value) => { // fon = field or value, such as math being a field and 1206 being a number
        if (fon == "field") {
            setCurInput1(value)
        }
        else if (fon == "number") {
            setCurInput2(value)
        }
    }

    const agreeRef = React.useRef(null)
    const [agree, setAgree] = React.useState(false)
    const handleAgree = () => {
        setAgree(agreeRef.current.checked)
        data.pptosagree = agreeRef.current.checked
    }

    const handleSubmit = () => {

        let out = {
            username: data.username[0],
            email: data.email[0],
            password: data.password[0],
            confirmPassword: data.confirmPassword[0],
            dob: data.dob[0],

            courses: data.courses,

            pptosagree: agree.toString()
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/signup"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                
                setRedirect(resp_json["redirect"])
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }
    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    if (props.stage == 1) {
        return (
            <>
            <Box sx={{display:"flex", flexDirection:"column", mt:4}}>
                <TextField 
                    value={usernameState}
                    sx={{}} 
                    error={!validUsername}
                    label="Username" 
                    variant="outlined" 
                    size="small"
                    onChange={(e) => handleTextInput("username", e.target.value)}
                ></TextField>
                <TextField 
                    value={emailState}
                    error={!validEmail}
                    helperText={( !validEmail ? "Invalid email, must end in @vt.edu" : "")}
                    sx={{mt:1}} 
                    label="Email" 
                    variant="outlined" 
                    size="small"
                    onChange={(e) => handleTextInput("email", e.target.value)}
                ></TextField>
                <Typography color="grey" sx={{ maxWidth:320, pl:2, pt:1}} variant="caption">Please use your vt.edu email. This will be private and we will never share your account with your school</Typography>
                <TextField 
                    value={passwordState}
                    error={!validPassword}
                    helperText={( !validPassword ? "Passord must be at least 8 characters long" : "")}
                    sx={{mt:1}} 
                    label="Password" 
                    type="password" 
                    variant="outlined" 
                    size="small"
                    onChange={(e) => handleTextInput("password", e.target.value)}
                ></TextField>
                <TextField 
                    value={confirmPasswordState}
                    error={!validConfirmPassword}
                    helperText={( !validConfirmPassword ? "Passwords do not match" : "")}
                    sx={{mt:1}} 
                    label="Confirm Password" 
                    type="password" 
                    variant="outlined" 
                    size="small"
                    onChange={(e) => handleTextInput("confirmPassword", e.target.value)}
                ></TextField>
                <Typography color="grey" sx={{pl:2, pt:1}} variant="caption">Password min length: 8 characters</Typography>
                <Box sx={{mt:2}}>
                    <LocalizationProvider sx={{width:"100%"}} dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Date of Birth"
                        inputFormat="MM/DD/YYYY"
                        value={value}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            </>
        );
    }

    else if (props.stage == 2) {
        return (
            <>
            <Box sx={{display:"flex"}}>
                <Typography variant="caption" sx={{color:"grey", maxWidth:"350px", textAlign:"center", m:"auto", mt:3}}>Enter the classes you are currently taking so we can customize your feed</Typography>
            </Box>
            <Box sx={{mt:3, display:"flex"}}>
                <TextField 
                value={curInput1}
                onChange={(e) => handleClassText("field", e.target.value)}
                size="small"
                inputProps={{ style: { textTransform: "uppercase" } }}
                helperText="Ex. MATH, CS, ENGE" 
                sx={{maxWidth:150, mr:1}} 
                label="Course field" 
                variant="filled" />
                <TextField 
                value={curInput2}
                onChange={(e) => handleClassText("number", e.target.value)}
                size="small" helperText="Ex. 101, 2305" sx={{maxWidth:150}} label="Course number" variant="filled" />
                <Button onClick={() => handleAddClass()} sx={{height:45, ml:1}} variant="contained">Add</Button>
            </Box>
            <Divider sx={{mt:2, mb:2}}><Typography variant="caption">Your Courses</Typography></Divider>
            <Box key={classes.length}>
                {renderClasses()}
            </Box>
            <Divider sx={{mt:2, mb:2}}></Divider>
            </>
        )
    }

    else if (props.stage == 3) {
        return(
            <>
            {renderNavigate()}
            <Box sx={{display:"flex", mt:4, mb:4}}>
                <Checkbox checked={agree} inputRef={agreeRef} onChange={() => handleAgree()} />
                <Typography sx={{margin:"auto 0"}}>I agree to the <a target="_blank" href="/priv_pol.pdf">privacy policy</a> and terms of service</Typography>
            </Box>
            <Box sx={{display:"flex", mt:3, mb:3}}>
                <Button onClick={() => handleSubmit()} disabled={!agree} size="large" sx={{ margin:"0 auto", width:"75%" }} variant="contained">Create Account</Button>
            </Box>
            </>
        );
    }
})
 
export default SignupInputs;