import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, InputLabel, FormControl, Card, CardContent, Icon, CardMedia, IconButton, Accordion, AccordionSummary, AccordionDetails, FormHelperText, Paper} from '@mui/material';
import { MathJax } from 'better-react-mathjax';
import { Navigate } from 'react-router-dom';


import DeleteIcon from '@mui/icons-material/Delete';
import FunctionsIcon from '@mui/icons-material/Functions';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

const HomeTabSearch = () => {

    const textRef = React.useRef(null);
    const courseFieldRef = React.useRef(null);
    const courseNumberRef = React.useRef(null);
    const authorRef = React.useRef(null);

    const [equations, setEquations] = React.useState([])
    

    const [select, setSelect] = React.useState("all");
    const handleSelectChange = (event) => {
        setSelect(event.target.value);
    };

    const [addOpen, setAddOpen] = React.useState(false)
    const handleOpenAddEquation = () => {
        setAddOpen(true)
    }
    const handleCloseAddEquation = () => {
        setAddOpen(false);
    }
    const handleAddEquation = (e) => {
        let newVal = e.target.parentNode.parentNode.childNodes[0].value; // gets value of field
        let temp = [...equations];
        temp.push(newVal);
        setEquations(temp);
        handleCloseAddEquation();

        e.target.parentNode.parentNode.childNodes[0].value = null; // clears field
    }
    const handleDeleteEquation = (i) => {
        let temp = [...equations];
        temp.splice(i, 1);
        setEquations(temp);
    }

    const renderEquations = () => {
        let temp = [...equations];
        let out = []
        for (let i = 0; i < temp.length; i++) {
            let val = temp[i];
            val = "\\(" + val + "\\)"
            out.push(
                <Box
                sx={{width:{xs:"90%", sm:"80%", md:"60%"}, margin:"auto", borderBottom:"1px solid grey", display:"flex", justifyContent:"space-between", alignItems:"center", mt:1}}
                key={i}
                >
                    <MathJax dynamic hideUntilTypeset={"first"} inline>{val}</MathJax>
                    <IconButton sx={{ml:2}} onClick={() => handleDeleteEquation(i)}><DeleteIcon/></IconButton>
                </Box>
            )
        }
        return out;
    }

    const handleSubmit = () => {
        let out = {
            "text": textRef.current.value,
            "equations": equations,
            "courseField": courseFieldRef.current.value,
            "courseNumber": courseNumberRef.current.value,
            "author": authorRef.current.value,
            "type": select,
            "csfr_token": window.localStorage.getItem("cToken")
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/presearch"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText)
                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                setRedirect(resp_json["redirect"])
            }
            else if (xhr.readyState === 4 && xhr.status == 400) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    return (
        <>
        {renderNavigate()}
        <Box sx={{maxWidth:1000, paddingX:"25px", mt:5, position:"relative", left:"50%", transform:"translateX(-50%)", display:"flex", flexDirection:"column"}}>
            
            <TextField
                sx={{width:{xs:"90%", sm:"80%", md:"60%"}, margin:"auto"}}
                label="Search"
                multiline
                maxRows={15}
                minRows={2}
                inputRef={textRef}
            >
            </TextField>
            {renderEquations()}
            <Box
                sx={{width:{xs:"90%", sm:"80%", md:"60%"}, margin:"auto", mt:2, display:"flex", alignItems:"center"}}
            >
                <Button disabled={equations.length > 0} onClick={() => handleOpenAddEquation()} sx={{mr:2}} startIcon={<FunctionsIcon />} variant="outlined">Add Equation</Button>
            </Box>
            {( equations.length > 0 ?
            <Box
                sx={{width:{xs:"90%", sm:"80%", md:"60%"}, margin:"auto", mt:0.5, display:"flex", alignItems:"center"}}
            >
                <Typography variant='caption'>This feature is still under development, limted to one equation</Typography>
            </Box>
            :""
            )}
            <Paper elevation={5}
            sx={{width:{xs:"90%", sm:"80%", md:"60%"}, margin:"auto", mt:2, display:"flex", alignItems:"center",
                padding:2,
                boxSizing:"border-box",
                borderBottom: "2px solid grey" , 
                position:"absolute",
                top:"75px",
                left:"50%",
                transform:"translateX(-50%)",
                display:(addOpen ? "flex" : "none"),
                justifyContent:"space-between",
            }}
            onMouseOver={() => document.getElementById("mathfield").focus()}
            >
                <math-field virtual-keyboard-mode="manual" onMouseOver={() => document.getElementById("mathfield").focus()} id="mathfield" style={{padding:"5px", maxWidth:"300px", minWidth:"200px", border:"2px solid", borderRadius:"5px"}}></math-field>
                <Box>
                    <Button onClick={() => handleCloseAddEquation()} sx={{ml:2}}>Close</Button>
                    <Button onClick={(e) => handleAddEquation(e)} sx={{ml:2}} startIcon={<AddIcon />} variant="contained">Add Equation</Button>
                </Box>
            </Paper>
            <Box mb={3}></Box>

            

            <Accordion
                sx={{width:{xs:"90%", sm:"80%", md:"60%"}, margin:"auto", mt:3}}
                disableGutters
                square
             >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography variant="subtitle2">Optional Search Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField inputRef={courseFieldRef} sx={{mr:2}} helperText="ex. MATH, CS, ENGL" size="small" label="Course Field" variant="filled" />
                    <TextField inputRef={courseNumberRef} helperText="ex. 1225, 101, 2505" size="small" label="Course Number" variant="filled" />
                    <Box sx={{mt:2}}></Box>
                    
                    <FormControl variant="filled" sx={{mr:2, minWidth: 206 }}>
                        <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
                        <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={select}
                        onChange={handleSelectChange}
                        size="small"
                        
                        >
                        <MenuItem value="all">- All -</MenuItem>
                        <MenuItem value="pdf">File</MenuItem>
                        <MenuItem value="qa">Question/Answer</MenuItem>
                        </Select>
                        <FormHelperText>ex. File, Q/A</FormHelperText>
                    </FormControl>

                    <TextField inputRef={authorRef} helperText="ex. username" size="small" label="Author" variant="filled" />
                    <Box sx={{mt:2}}></Box>
                </AccordionDetails>
            </Accordion>

            <Button
            sx={{minWidth:"200px", margin:"auto", mt:5}}
            variant="contained"
            size="large"
            onClick={() => handleSubmit()}
            >Submit</Button>
        </Box>
        
        <Box sx={{mb:10}}></Box>
        </>
    );
}

// <math-field>f(x) = \sin(x+\pi)</math-field>

// {i == equations.length - 1 ? <IconButton disabled={i != equations.length - 1} onClick={() => deleteEquation(i)}><DeleteIcon/></IconButton> : ""}
 
export default HomeTabSearch;