import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, InputLabel, FormControl, Card, CardContent, Icon, CardMedia, IconButton, Accordion, AccordionSummary, AccordionDetails, FormHelperText, Paper} from '@mui/material';
import { MathJax } from 'better-react-mathjax';
import { Navigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCookies } from 'react-cookie';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';

const AccountActions = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['login_state_cont']);

    const [open, setOpen] = React.useState(false);

    const recaptchaRef = React.useRef(null);

    const handleDeleteAccount = async () => {
        if (window.confirm("You are about to delete your account Click OK to continue.")) {
            let out = {
                "recaptcha_token": await recaptchaRef.current.executeAsync(),
                "csfr_token": window.localStorage.getItem("cToken")
            }

            let xhr = new XMLHttpRequest();
            let url = "/api/deleteaccount"
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json")
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    let resp_json = JSON.parse(xhr.responseText);

                    setCookie("login_state_cour", "false-oooo")
                    
                    setRedirect(resp_json["redirect"])
                }
                else if (xhr.readyState === 4 && xhr.status === 500) {
                    alert("Unknow server error!")
                }
                else if (xhr.readyState === 4 && xhr.status != 200) {
                    let resp_json = JSON.parse(xhr.responseText);
                    alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
                }
            }
            let post_json = JSON.stringify(out)
            xhr.send(post_json)
        }
    }

    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    return (
        <>
        {renderNavigate()}
        <div id="content">
            <TopBar loginState="true"></TopBar>
            <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LcIz3EkAAAAAEPhHJp2EoHtqV_Ik4kT4K38WOiq"
            />
            <Box>
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", mt:10, flexDirection:"column"}}>
                    <Typography variant='h4'>Account Actions</Typography>
                    <Button onClick={() => setOpen(true)} sx={{mt:4}} variant="contained">Delete Account</Button>
                    {(open ? 
                    <Paper sx={{mt:2, padding:2, display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        <Typography>Are You sure?</Typography>
                        <Button onClick={() => handleDeleteAccount()} sx={{mt:4}} variant="outlined">Yes Delete My Account</Button>
                        <Button onClick={() => setOpen(false)} sx={{mt:4}} variant="contained">No Keep my account</Button>
                    </Paper>
                    :
                    ""
                    )}
                </Box>
            </Box>
        </div>
        <Footer></Footer>
        </>
    );
}
 
export default AccountActions;