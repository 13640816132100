import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel} from '@mui/material';
import { MathJax } from 'better-react-mathjax';


import FunctionsIcon from '@mui/icons-material/Functions';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import AddLinkIcon from '@mui/icons-material/AddLink';

const QuestionAnswerInput = (props) => {

    const [imageOpen, setImageOpen] = React.useState(false);
    const handleOpenAddImage = () => {
        setImageOpen(true);
    }
    const handleCloseAddImage = () => {
        setImageOpen(false);
    }
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
        } else if (e.type === "dragleave") {
        setDragActive(false);
        }
    };
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    };
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };
    const acceptedFiles = ["image/png", "image/jpeg", "image/jpg"]
    const handleFiles = (files) => { // also adds image to inputs
        if (acceptedFiles.indexOf(files[0].type) < 0) {
            alert("File type not supported \nPlease upload " + acceptedFiles.join(", "))
        }
        else {
            let curImage = files[0];
            let temp = [...inputs];
            temp.push(["image", curImage]);
            temp.push(["text", ""]);
            setInputs(temp);
            inputRef.current.value = null; // clears input
            handleCloseAddImage();
        }
    }


    const [inputs, setInputs] = React.useState([["text", ""]]);

    const handleResizeTextarea = (e, i) => {
        e.target.style.height = 0;
        e.target.style.height = e.target.scrollHeight + 1 + "px";
    }

    const handleUpdateInput = (e, i) => {
        e.preventDefault()
        let temp = [...inputs];
        temp[i][1] = e.target.value;
        setInputs(temp);
    }

    const [equations, setEquations] = React.useState([])
    const [course, setCourse] = React.useState('');
    const handleCourseChange = (event) => {
        setCourse(event.target.value);
    };
    const [addOpen, setAddOpen] = React.useState(false)
    const handleOpenAddEquation = () => {
        setAddOpen(true)
    }
    const handleCloseAddEquation = () => {
        setAddOpen(false);
    }
    let bug_replaces = [ // fixes discrepancies between mathjax and live math input
        ["\\differentialD", "d"]
    ]
    const handleAddEquation = (e) => {
        let newVal = e.target.parentNode.parentNode.childNodes[0].value; // gets value of field
        
        for (let i = 0; i < bug_replaces.length; i++) {
            newVal = newVal.replaceAll(bug_replaces[i][0],bug_replaces[i][1])
        }
        
        let temp = [...inputs];
        temp.push(["eq", newVal]);
        temp.push(["text", ""]);
        setInputs(temp);
        handleCloseAddEquation();

        e.target.parentNode.parentNode.childNodes[0].value = null; // clears field
    }
    const handleDeleteSpecial = (i) => { // works idk how, but it works
        let temp = [...inputs];
        temp.splice(i, 1);

        temp[i-1][1] = temp[i-1][1] + "\n" + temp[i][1];
        let textIndex = 0;
        for (let j=0; j<i; j++) {
            if (inputs[j][0] == "text") {
                textIndex++;
            }
        }
        document.getElementsByTagName("textarea")[textIndex-1].value = document.getElementsByTagName("textarea")[textIndex-1].value + "\n" + document.getElementsByTagName("textarea")[textIndex].value
        document.getElementsByTagName("textarea")[textIndex-1].style.height = 0;
        document.getElementsByTagName("textarea")[textIndex-1].style.height = document.getElementsByTagName("textarea")[textIndex-1].scrollHeight + 1 + "px"
        temp.splice(i, 1);

        setInputs(temp);
    }

    const renderInputs = () => {
        let out = []

        if (inputs.length > 0) {
            for (let i = 1; i < inputs.length; i++) {
                if (inputs[i][0] == "text") {
                    out.push(
                        <textarea defaultValue={inputs[i][1]} key={i} onKeyUp={(e) => handleUpdateInput(e, i)} onInput={(e) => handleResizeTextarea(e, i)} style={{resize:"none", width:"100%", fontSize:"15px", border:"none", padding:"5px", boxSizing:"border-box", height:"40px"}}>
                            
                        </textarea>
                    )
                }
                else if (inputs[i][0] == "eq") {
                    let val = inputs[i][1]
                    val = "\\(" + val + "\\)"
                    out.push(
                        <Box
                        sx={{minWidth:"100%", margin:"auto", borderBottom:"1px solid grey", display:"flex", justifyContent:"space-between", alignItems:"center", mb:1}}
                        key={i}
                        >
                            <MathJax dynamic hideUntilTypeset={"first"} inline>{val}</MathJax>
                            <IconButton sx={{ml:2}} onClick={() => handleDeleteSpecial(i)}><DeleteIcon/></IconButton>
                        </Box>
                    )
                }
                else if (inputs[i][0] == "image") {
                    let url = ""
                    try {
                        url = window.URL.createObjectURL(inputs[i][1]) // will fail after upload
                    }
                    catch {
                        url = ""
                    }
                    out.push(
                        <Box key={i} sx={{minWidth:"100%", margin:"auto", borderBottom:"1px solid grey", display:"flex", justifyContent:"space-between", alignItems:"center", mb:1}}                        >
                            <img style={{width:"75%", marginBottom:"5px"}} src={url} />
                            <IconButton sx={{ml:2}} onClick={() => handleDeleteSpecial(i)}><DeleteIcon/></IconButton>
                        </Box>
                    )
                }
            }
        }
        return out;
    }

    const handleSubmit = () => {
        props.addNewAnswer(inputs)
    }

    return (
        <>
        <Box>
            <Box sx={{width:"100%", margin:"auto"}}>

                <Box sx={{boxSizing:"border-box", padding:1, width:"100%", minHeight:"150px", border:"1px solid lightGrey", borderRadius:"10px", backgroundColor:"white"}}>
                    <textarea defaultValue={inputs[0][1]} onKeyUp={(e) => handleUpdateInput(e, 0)} onInput={(e) => handleResizeTextarea(e, 0)} placeholder="Answer..." style={{resize:"none", width:"100%", fontSize:"15px", border:"none", padding:"5px", boxSizing:"border-box"}}>
                        
                    </textarea>

                    {renderInputs()}

                </Box>
            </Box>

            <Box sx={{width:"100%", margin:"auto"}}>
                <Box sx={{position:"relative"}}>
                    <Box
                        sx={{mt:2, display:"flex"}}
                    >
                        <Button onClick={() => handleOpenAddEquation()} sx={{mr:2}} startIcon={<FunctionsIcon />} variant="outlined">Insert Equation</Button>
                        <Button onClick={() => handleOpenAddImage()} sx={{mr:2}} startIcon={<ImageIcon />} variant="outlined">Insert Image</Button>

                    </Box>
                    <Paper elevation={5}
                    sx={{width:"110%", maxWidth:"750px", margin:"auto", mt:2, display:"flex", alignItems:"center",
                        padding:2,
                        boxSizing:"border-box",
                        borderBottom: "2px solid grey" , 
                        position:"absolute",
                        top:"-25px",
                        left:"50%",
                        transform:"translateX(-50%)",
                        display:(addOpen ? "flex" : "none"),
                        justifyContent:"space-between",
                        zIndex:10
                    }}
                    >
                        <math-field virtual-keyboard-mode="manual" onMouseOver={() => document.getElementById("mathfield").focus()} id="mathfield" style={{padding:"5px", maxWidth:"300px", minWidth:"200px", border:"2px solid", borderRadius:"5px"}}></math-field>
                        <Box>
                            <Button onClick={() => handleCloseAddEquation()} sx={{ml:2}}>Close</Button>
                            <Button onClick={(e) => handleAddEquation(e)} sx={{ml:2}} startIcon={<AddIcon />} variant="contained">Add Equation</Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>

        <Button variant="contained" sx={{mt:5}} onClick={() => handleSubmit()}>Submit</Button>

        <Paper elevation={20} sx={{display:(imageOpen ? "block" : "none"), position:"fixed", width:"90vw", height:"90vh", left:"50%", top:"50%", transform:"translate(-50%,-50%)", zIndex:"2"}}>
                <Box sx={{display:"flex", flexDirection:"column", mt:3}}>
                    <form style={{height:400, width:"80%", maxWidth:500, textAlign:"center", margin:"auto", border:"2px dashed", borderRadius:"10px", display:"flex", alignItems:"center", alignContent:"center", marginTop:"35px"}} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <Box width="100%">
                            <input accept="image/png, image/jpeg, image/jpg" style={{display:"none"}} ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                                <div>
                                <Box>
                                    <Typography variant="button">Drag and drop your file here or</Typography>
                                </Box>
                                <Button sx={{mt:2}} variant="outlined" className="upload-button" onClick={onButtonClick}>Upload a file</Button>
                                <Box>
                                    <Typography variant="overline">PNG, JPEG, JPG</Typography>
                                </Box>
                                </div> 
                            </label>
                            { dragActive && <div style={{position:"absolute", width:"100%", height:"100%", top:"0px", left:"0px"}} id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                        </Box>
                    </form>
                    <Button onClick={() => handleCloseAddImage()} sx={{margin:"auto", mt:3}}>Close</Button>
                
                </Box>
            </Paper>
        </>
    );
}
 
export default QuestionAnswerInput;