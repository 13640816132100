import * as React from 'react';
import {useState, forwardRef, useImperativeHandle} from 'react';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, TextField, Paper, Checkbox} from '@mui/material';
import { Navigate } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const ForgotPassword = () => {

    const [sent, setSent] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const inputRef = React.useRef(null)

    const handleSubmit = () => {
        setLoading(true)
        let email = inputRef.current.value;

        let out = {
            "email":email
        }
        let xhr = new XMLHttpRequest();
        let url = "/api/sendforgotpassword"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                window.localStorage.setItem("cToken", resp_json["csfr_token"])
                setSent(true)
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
            setLoading(false)
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)

    }

    return (
        <>
        {(!loading ? "" :
            <Box sx={{position:"fixed", top:"0px", left:"0px", width:"100%", height:"100%", zIndex:"100", bgcolor:"rgba(0,0,0,0.5)", backdropFilter:"blur(3px)", display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <link rel="stylesheet" href="/loader.css"></link>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </Box>
            </Box>
        )}
        <Box sx={{display:"flex", alignItems:"center", mt:"20%", flexDirection:"column"}}>
            <Box sx={{display:"flex", alignItems:"center"}}>
                    <Box
                    component="img"
                    sx={{
                    width: 55,
                    cursor: "pointer",
                    mr: 2
                    }}
                    alt="Courseology logo"
                    src="/logo.png"
                    />
                    <Typography variant="h4" sx={{height:"100%", poition:"relative"}}>Courseology</Typography>
            </Box>
            {(!sent ? 
            <Box sx={{display:"flex", flexDirection:"column", mt:5, textAlign:"center", alignItems:"center"}}>
                <Typography sx={{mb:1}} variant='h6'>Forgot Password</Typography>
                <Typography>Please enter the email you used to signup for our service</Typography>
                <TextField inputRef={inputRef} sx={{mt:3, width:"100%"}} variant="filled" label="Email" />
                <Button onClick={() => handleSubmit()} size="large" sx={{width:120, mt:2}} variant="contained">Submit</Button>
            </Box>
            :
            <Box sx={{display:"flex", flexDirection:"column", mt:5, textAlign:"center", borderBottom:"1px solid", paddingBottom:2}}>
                <Typography sx={{mb:1}} variant='h6'>Check your inbox</Typography>
                <Typography>Check your inbox for an email from noreply@courseology.net</Typography>
                <Typography>Then Click the "Reset Password" Box or follow the provided url</Typography>
                <Typography>Be sure to check your spam folder</Typography>
                <Box sx={{display:"flex", margin:"auto", mt:2, borderBottom:"3px solid green"}}>
                    <ForwardToInboxIcon sx={{mr:1, color:'green'}} />
                    <Typography sx={{color:'green'}} variant="button">Email sent!</Typography>
                </Box>
            </Box>
            )}
        </Box>
        </>
    );
}
 
export default ForgotPassword;