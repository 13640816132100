import * as React from 'react';
import {useState, forwardRef, useImperativeHandle} from 'react';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, TextField, Paper, Checkbox} from '@mui/material';
import { Navigate } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const Verifyemail = () => {
    return (
        <>
        <Box mt="15vh" sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <Box sx={{display:"flex", alignItems:"center"}}>
                    <Box
                    component="img"
                    sx={{
                    width: 55,
                    cursor: "pointer",
                    mr: 2
                    }}
                    alt="Courseology logo"
                    src="/logo.png"
                    />
                    <Typography variant="h4" sx={{height:"100%", poition:"relative"}}>Courseology</Typography>
            </Box>
            <Box sx={{display:"flex", flexDirection:"column", mt:5, textAlign:"center", borderBottom:"1px solid", paddingBottom:2}}>
                <Typography sx={{mb:1}} variant='h6'>Please verify your email</Typography>
                <Typography>Check your inbox for an email from noreply@courseology.net</Typography>
                <Typography>Then Click the "Verify email" Box or follow the provided url</Typography>
                <Typography>Be sure to check your spam folder</Typography>
                <Box sx={{display:"flex", margin:"auto", mt:2, borderBottom:"3px solid green"}}>
                    <ForwardToInboxIcon sx={{mr:1, color:'green'}} />
                    <Typography sx={{color:'green'}} variant="button">Email sent!</Typography>
                </Box>
            </Box>
        </Box>
        </>
    );
}
 
export default Verifyemail;