import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MathJaxContext } from 'better-react-mathjax';
import { CookiesProvider } from "react-cookie";
import { useCookies } from 'react-cookie';

import App from './App';
import Login from './Login';
import Signup from './Signup';
import Home from './Home';
import Question from './Question';
import Verifyemail from './Verifyemail';
import VerifyemailCode from './VerifyemailCode';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';
import File from './File';
import SearchRes from './SearchRes';
import AccountActions from './AccountActions';
import Contact from './Contact';
import CiteViewer from './CiteViewer';

import { CSFRTokenProvider } from './CSFRTokenContext';

let theme = createTheme({
  palette: {
    primary: {
      main: '#630031',
    },
    default: {
      main: '#FFFFFF',
    },
    text: {
      main: '#000000',
    },
    textSub: {
      main: '#FF9191',
    }
  },
});

const betterReactMathjaxConfig = {
  "fast-preview": {
    disabled: true
  },
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  },
  messageStyle: "none"
};

const Index = () => {

    return (
        //<React.StrictMode>
        <MathJaxContext
        version={2}
        config={betterReactMathjaxConfig}
        onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}
        >
        <CSFRTokenProvider>
            <ThemeProvider theme={theme}>
            <BrowserRouter>
                <CookiesProvider>
                <Routes>
                    <Route path="/" element={<App />}/>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/signup" element={<Signup />}/>
                    <Route path="/home" element={<Home tab="yourfeed" />}/>
                    <Route path="/home/:tab" element={<Home />}/>
                    <Route path="/question/:q" element={<Question />} />
                    <Route path="/file/:f" element={<File />} />
                    <Route path="/verifyemail/:code" element={<VerifyemailCode />} />
                    <Route path="/verifyemail" element={<Verifyemail />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/passwordreset/:code" element={<PasswordReset />} />
                    <Route path="/search/:text/:equation/:page/:course/:author/:media_type" element={<SearchRes />} />
                    <Route path="/accountactions" element={<AccountActions />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/citations" element={<CiteViewer />} />
                </Routes>
                </CookiesProvider>
            </BrowserRouter>
            </ThemeProvider>
            </CSFRTokenProvider>
        </MathJaxContext>
        //</React.StrictMode>
    );
}
 
export default Index;