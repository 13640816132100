import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, InputLabel, FormControl, Card, CardContent, Icon, CardMedia, IconButton, Accordion, AccordionSummary, AccordionDetails, FormHelperText, Paper} from '@mui/material';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';

const Contact = () => {
    return (
        <>
        <div id="content">
            <TopBar loginState="true"></TopBar>
            <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                <Box sx={{mt:5, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <Typography variant='h4'>Contact</Typography>
                    <Typography sx={{mt:3}}>Email: contact@courseology.net</Typography>
                    <Typography >Instagram: courseology_vt</Typography>
                    <Typography variant="body2" sx={{mt:3, maxWidth:450, textAlign:"center"}}>
                        Please try to report any bugs you encounter while using our services by emailing us with steps on how to reproduce. If you would like to contact our founder please email us at contact@courseology.net and they will reply as soon as possible. If you have any questions feel free to email us anytime and we will do our best to answer as fast as we can. Keep in mind that our website is maintained by a few college students with busy schedules. We hope that your experience on our site has been satisfactory.
                    </Typography>
                </Box>
            </Box>
        </div>
        <Footer></Footer>
        </>
    );
}
 
export default Contact;