import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab} from '@mui/material';

import HomeTabFeed from './homeTabs/HomeTabFeed';
import HomeTabSearch from './homeTabs/HomeTabSearch';
import HomeTabUpload from './homeTabs/HomeTabUpload';
import HomeTabAsk from './homeTabs/HomeTabAsk';
import HomeTabProfile from './homeTabs/HomeTabProfile';
import HomeTabMyPosts from './homeTabs/HomeTabMyPosts';
import Cite from './Cite';

const HomeTab = (props) => {
    const tab = props.tab;

    if (tab == 0) {
        window.history.replaceState({}, "", "/home/feed")
        return (<HomeTabFeed></HomeTabFeed>);
    }
    else if (tab == 1) {
        window.history.replaceState({}, "", "/home/search")
        return (<HomeTabSearch></HomeTabSearch>);
    }
    else if (tab == 2) {
        window.history.replaceState({}, "", "/home/upload")
        return (<HomeTabUpload></HomeTabUpload>)
    }
    else if (tab == 3) {
        window.history.replaceState({}, "", "/home/ask")
        return (<HomeTabAsk></HomeTabAsk>)
    }
    else if (tab == 4) {
        window.history.replaceState({}, "", "/home/cite")
        return (<Cite></Cite>)
    }
    else if (tab == 5) {
        window.history.replaceState({}, "", "/home/profile")
        return (<HomeTabProfile></HomeTabProfile>)
    }
    else if (tab == 6) {
        window.history.replaceState({}, "", "/home/posts")
        return (<HomeTabMyPosts></HomeTabMyPosts>)
    }

}
 
export default HomeTab;