import * as React from 'react';
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';

function App() {

  const theme = useTheme();

  return (
    <>
      <div id="content">
        <TopBar loginState="false"></TopBar>
        <Box padding="0px 20px" margin="auto" maxWidth="md" sx={{ display:"flex", flexDirection:"column", maxWidth:"631px"}}>
          <Box
          component="img"
          sx={{
            alignSelf:{sm:"center"},
            width: 100,
            mt: 5,
            display:{xs:"none", sm:"flex"},
          }}
          alt="Courseology logo"
          src="logo.png"
          />
          <Typography variant="h2" sx={{flexBias:"100px", mt:{xs:5, sm:0}, typography: { sm: 'h2', xs:'h4' } }}>Introducing Courseology</Typography>
          <Typography variant='h6' mt={1} mb={1}>Study smarter, for free, and with no distractions</Typography>
          <Typography mt={0} variant="body2" sx={{flexShrink:"1", flexBias:"200px", width:"80%"}}>Courseology is an education website that offers a unique learning experience for students. This site allows students to connect with each other, share notes, ask questions, and discuss courses in a collaborative online community. This added social aspect of the site helps students learn from each other, providing a more engaging and interactive way to learn. With its interactive community, Courseology is a valuable resource for anyone looking to further their education. Best of all, its <b>100% free!</b></Typography>
          <Grid container spacing={2} mt={0}>
            <Grid item><Button component={Link} to="/signup" size="medium" variant="contained">sign up for free</Button></Grid>
            <Grid item><Button component={Link} to="/login" size="medium" variant="outlined">login</Button></Grid>
          </Grid>
        </Box>

        <Box width="100%" height={100}></Box>

        <Box padding="0px 20px" margin="auto" maxWidth={600} sx={{position:"relative", textAlign:"center"}}>
          <Box sx={{
            minWidth:"1200px",
            maxWidth:"2200px",
            width:"120vw", 
            height:"130%", 
            backgroundColor:"#F6F6F6", 
            zIndex:"-1",
            position:"absolute",
            left:"50%",
            top:"-50%",
            borderRadius:"500px",
            transformOrigin:"center",
            transform:"rotate(-3deg) translateX(-50%) translateY(20%)"
            }}></Box>
          <Typography mb={1} variant="h5">What schools are we currently at?</Typography>
          <Typography mb={2} variant="body2">Currently our website is exclusive to Virginia Tech. However, anyone can still view content uploaded to our website. In the near future we are planning to expand to schools throughout Virginia, Maryland, and D.C. It is our goal to soon provide our services to universities throughout the United States and across the globe.</Typography>
          <Typography margin="auto" variant="overline">coming soon...</Typography>
          <Box
          margin="auto"
          component="img"
          sx={{
            width:300,
            display: "block"
          }}
          alt="Courseology logo"
          src="comingsoonlogos.png"
          />
          <Typography mt={5} mb={1} variant="h5">What services do we offer?</Typography>
          <Typography mb={2} variant="body2">We offer a multitude of services to allow students to learn more efficiently. We have features that allow students to: share notes, ask questions, join classes, and create discussions. We also have many more features currently under development!</Typography>
        </Box>

        <Box width="100%" height={250}></Box>

      </div>
      <Footer></Footer>
    </>
  );
}

export default App;
