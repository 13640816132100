import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, InputLabel, FormControl, Card, CardContent, Icon, CardMedia, IconButton, Accordion, AccordionSummary, AccordionDetails, FormHelperText, Paper} from '@mui/material';
import { MathJax } from 'better-react-mathjax';
import ReCAPTCHA from 'react-google-recaptcha';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import PostRenderer from './basic/PostRenderer';

const SearchRes = () => {

    const [res, setRes] = React.useState(null)
    const params = useParams(); //url

    const gen_search_val = () => {
        return params.text+"__/__"+params.equation+"__/__"+params.course+"__/__"+params.author+"__/__"+params.media_type
    }

    React.useEffect(() => {
        if (sessionStorage.getItem("last_search") == gen_search_val()) {
            let cached_results = JSON.parse(sessionStorage.getItem("last_search_res"))["results"]
            setRes(cached_results)
        }
    }, []);

    const verifyCallback = (e) => {

        let out = {
            "recaptcha_token": e,
            "csfr_token": window.localStorage.getItem("cToken"),
            "text": params.text,
            "equation": params.equation,
            "page": params.page,
            "course": params.course,
            "author": params.author,
            "media_type": params.media_type
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/searchres"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                setRes(resp_json["results"])
                sessionStorage.setItem("last_search", gen_search_val())
                sessionStorage.setItem("last_search_res", JSON.stringify(resp_json))
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const [postsInView, setPostsInView] = React.useState([])

    const [curPage, setCurPage] = React.useState(params.page)

    React.useEffect(() => {

        if (res == null){return}

        let page = params.page 
        
        let out = []

        let start = page * 20
        let end = (page + 1) * 20
        if (end > res.length) {
            end = res.length
        }

        for (let i = start; i < end; i++) {
            out.push(res[i])
        }

        setPostsInView(out)

    }, [res, curPage]);


    if (res == null) {
        return (
            <>
            <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            <Box sx={{display:"flex", justifyContent:"center"}}>
                <Box sx={{mt:"25vh"}}>
                    <Typography variant='h5'>Please verify your humanity</Typography>
                    <Typography sx={{mb:2}} variant='body2'>After you will be redirected to your search results</Typography>
                    <form><ReCAPTCHA size="normal" onChange={verifyCallback} sitekey='6LeeG1wkAAAAALy5eaPk3zmcvBxim2pOXylWxM4V' /></form>
                    <Typography sx={{mb:2}} variant='caption'>If recaptcha checkbox does not load please refresh page</Typography>
                </Box>
            </Box>
            </>
        )
    }
    return (
        <>
        <div id="content">
            <TopBar loginState="true"></TopBar>
            <Box>
                <Box sx={{width:"90%", position:"relative", left:"50%", transform:"translateX(-50%)", mt:3, mb:3}}>
                    <Button startIcon={<ArrowBackIcon />} component={Link} to="/home/search" size="medium">Search again</Button>
                </Box>
                <Box sx={{width:"90%", maxWidth:900, position:"relative", left:"50%", transform:"translateX(-50%)", mt:3, mb:3}}>
                    {( params.text != "__null__" ?
                        <Typography variant='body1'>Showing results for <i>"{params.text}"</i></Typography>
                        : ""
                    )}
                    {( params.equation != "__null__" ?
                        <Typography variant='body1'><MathJax dynamic hideUntilTypeset={"first"} inline>{"With equation: \\("+params.equation+"\\)"}</MathJax></Typography>
                        : ""
                    )}
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", mt:3}}>
                    <PostRenderer posts={postsInView} />
                    {(postsInView.length != 0 ? "" :
                    <Box sx={{mt:2, mb:4, display:"flex", width:"90%", position:"relative", left:"50%", transform:"translateX(-50%)", alignItems:"center", flexDirection:"column"}}>
                        <Typography variant='h5'>No matching posts</Typography>
                        <Typography>Please go back a page or enter a new query</Typography>
                    </Box>
                    )}
                    {(postsInView.length >= 20 || postsInView.length == 0 ? "" :
                    <Box sx={{mt:2, mb:4, display:"flex", width:"90%", position:"relative", left:"50%", transform:"translateX(-50%)", alignItems:"center", flexDirection:"column"}}>
                        <Typography variant='h5'>No more posts</Typography>
                        <Typography>Please go back a page or enter a new query</Typography>
                    </Box>
                    )}
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", mb:3}}>
                    <Button disabled={curPage == 0} onClick={() => setCurPage(curPage - 1)} component={Link} to={"/search/"+params.text+"/"+params.equation+"/"+(parseInt(params.page) - 1)+"/"+params.course+"/"+params.author+"/"+params.media_type} startIcon={<ArrowBackIosIcon />} variant='outlined' sx={{mr:3}}>Prev</Button>
                    <Button disabled={postsInView.length < 20} onClick={() => setCurPage(curPage + 1)} component={Link} to={"/search/"+params.text+"/"+params.equation+"/"+(parseInt(params.page) + 1)+"/"+params.course+"/"+params.author+"/"+params.media_type} endIcon={<ArrowForwardIosIcon />} variant='outlined'>Next</Button>
                </Box>
            </Box>
        </div>
        <Footer></Footer>
        </>
    );
}
 
export default SearchRes;