import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel} from '@mui/material';

const HomeTabProfile = () => {

    const [user, setUser] = React.useState(null);
    const [courses, setCourses] = React.useState([]);

    React.useEffect(() => {
        let xhr = new XMLHttpRequest();
        let url = "/api/curuser"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                setUser(resp_json["user"])
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        xhr.send()
    }, [])

    React.useEffect(() => {
        let xhr = new XMLHttpRequest();
        let url = "/api/courses"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
                setCourses(resp_json["courses"])
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        xhr.send()
    }, [])

    const renderCourses = () => {
        let out = [];
        for (let i = 0; i < courses.length; i++) {
            let field = courses[i].split(" ")[0];
            let number = courses[i].split(" ")[1]
            out.push(
                <Box key={i} sx={{margin:"auto", mb:1}}>
                    <Typography sx={{mr:1, display:"inline-block", width:125, p:1, backgroundColor:"lightGrey"}}>{field}</Typography>
                    <Typography sx={{display:"inline-block", width:125, p:1, backgroundColor:"lightGrey"}}>{number}</Typography>
                    <Button onClick={() => handleDeleteCourse(i)} sx={{ml:1}}>Delete</Button>
                </Box>
            );
        }
        return out;
    }

    const courseFieldRef = React.useRef(null)
    const courseNumberRef = React.useRef(null)

    const handleAddCourse = () => {
        let temp = [...courses]
        temp.push(courseFieldRef.current.value.toUpperCase() + " " + courseNumberRef.current.value.toUpperCase())
        setCourses(temp)
        updateCourses(temp)
    }

    const handleDeleteCourse = (index) => {
        let temp = [...courses]
        temp.splice(index, 1)
        setCourses(temp)
        updateCourses(temp)
    }

    const updateCourses = (newCourses) => {

        let out = {
            "courses": newCourses
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/updateCourses"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const UsernamRef = React.useRef(null);

    return (
        <>
        
        <Box sx={{width:"90%", maxWidth:"700px", padding:"10px", boxSizing:"border-box", margin:"auto", mt:5, display:"flex", flexDirection:"column"}}>
            <Box sx={{margin:"auto", display:"flex", alignItems:"center"}}>
                <Typography variant="h4">@{user}</Typography>
            </Box>

            <Box sx={{width:"95%", borderBottom:"1px solid grey", margin:"auto", mt:4}}></Box>

            <Box sx={{display:"flex"}}>
                <Typography variant="subtitle2" sx={{color:"grey", maxWidth:"350px", textAlign:"center", m:"auto", mt:3}}>Update/change your courses</Typography>
            </Box>
            <Box sx={{mt:2, display:"flex", justifyContent:"center"}}>
                <TextField 
                inputRef={courseFieldRef}
                size="small"
                inputProps={{ style: { textTransform: "uppercase" } }}
                helperText="Ex. MATH, CS, ENGE" 
                sx={{maxWidth:150, mr:1}} 
                label="Course field" 
                variant="filled" />
                <TextField 
                inputRef={courseNumberRef}
                size="small" helperText="Ex. 101, 2305" sx={{maxWidth:150}} label="Course number" variant="filled" />
                <Button onClick={() => handleAddCourse()} sx={{height:45, ml:1}} variant="contained">Add</Button>
            </Box>
            <Divider sx={{width:"50%", margin:"auto", mt:2, mb:2}}><Typography variant="caption">Your Courses</Typography></Divider>
            <Box sx={{display:"flex", flexDirection:"column"}}>
                {renderCourses()}
            </Box>

        </Box>

        <Box sx={{mb:10}}></Box>
        </>
    );
}
 
export default HomeTabProfile;