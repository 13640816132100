import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Badge, Typography, Box, AppBar, Toolbar, Button, Divider, Container, Grid, Tabs, Tab} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HistoryIcon from '@mui/icons-material/History';
import HomeIcon from '@mui/icons-material/Home';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { useCookies } from 'react-cookie';

import TopBar from './basic/TopBar';
import Footer from './basic/Footer';
import HomeTab from './HomeTab';

const Home = (props) => {

    const [cookies, setCookie, removeCookie] = useCookies(['login_state_cont']);

    const params = useParams(); //url
    let tab = "yourfeed";
    if (props.tab != "yourfeed") {
        tab = params.tab;
    }

    let tabIndex = 0;
    let tabs = ["yourfeed", "search", "upload", "ask", "cite", "profile", "posts"];
    if (tabs.indexOf(tab) >= 0) {
        tabIndex = tabs.indexOf(tab);
    }

    const [tabValue, setTabValue] = React.useState(tabIndex);
    const handleChange = (event, newValue) => { // for tabs
        setTabValue(newValue);
    };

    React.useEffect(() => {
        if (cookies["login_state_cour"] != "true") {
            setRedirect("/login")
        }
    }, [])

    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    return (
    <>
    {renderNavigate()}
    <div id="content">
    <TopBar loginState="true"></TopBar>
    <Box sx={{display:"flex"}}>
        <Box sx={{m:"auto", maxWidth: { xs: "100%", sm: "90%" } }}>
            <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            >
                <Tab label="Your Feed" icon={<HomeIcon />} iconPosition="start" />
                <Tab label="Search" icon={<SearchIcon />} iconPosition="start" />
                <Tab label="Upload File" icon={<UploadIcon />} iconPosition="start" />
                <Tab label="Ask" icon={<QuestionAnswerIcon />} iconPosition="start" />
                <Tab label="Cite" icon={<Badge anchorOrigin={{vertical: 'top',horizontal: 'right'}} variant="dot" color="info"><AutoStoriesIcon /></Badge>} iconPosition="start" />
                <Tab label="Profile" icon={<AccountBoxIcon />} iconPosition="start" />
                <Tab label="My Posts" icon={<HistoryIcon />} iconPosition="start" />
            </Tabs>
        </Box>
    </Box>

    <HomeTab tab={tabValue}></HomeTab>

    </div>
    <Footer></Footer>
    </>
    );
}
 
export default Home;