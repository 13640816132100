import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { AccordionDetails, AccordionSummary, Accordion, Autocomplete, Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel} from '@mui/material';
import { Navigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CAuthor from './CiteUtils/CAuthor';
import CDate from './CiteUtils/CDate';
import CText from './CiteUtils/CText';
import COptional from './CiteUtils/COptional';

const styles = ["APA (American Psychological Association)", "MLA (Modern Language Association)", "Chicago (Turabian)", "IEEE (Institute of Electrical and Electronics Engineers)", "CSE (Council of Science Editors)", "AAA (American Anthropological Association)", "APSA (American Political Science Association)", "ASA (American Sociological Association)", "ACS (American Chemical Society)", "AMA (American Medical Association)", "NLM (National Library of Medicine)", "Vancouver", "Bluebook", "OSCOLA", "Harvard"]

const media = ["website","book","article","video","image","legal","audio","artwork","report","thesis","proceedings","patent","dataset","manual","misc"]

const media_data = {
    "website": {
        "autofill": true,
        "autofill_title": "Enter the URL to autofill as many fields as possible.",
        "required": ["author","title","date","url","urldate"],
        "r_alisis": ["Author(s)","Website title","Date of publication","url","Access date"]
    },
    "book": {
        "autofill": true,
        "autofill_title": "Enter ISBN to autofill fields",
        "required": ["author","title","date","publisher"],
        "r_alisis": ["Author(s)","Book title","Date of publication","Publisher"],
        "optional": ["volume","edition","isbn","url"]
    },
    "article": {
        "autofill": true,
        "autofill_title": "Enter DOI to autofill fields",
        "required": ["author","title","journaltitle","date"],
        "r_alisis": ["Author(s)","Article Title","Journal Title","Date of publication"],
        "optional": ["volume","series","issue","doi"]
    },
    "video": {
        "auotfill": false,
        "required": ["author","title","date"],
        "r_alisis": ["Author(s)","Video title","date"],
        "optional": ["url"]
    },
    "image": {
        "auotfill": false,
        "required": ["author","title","date"],
        "r_alisis": ["Author(s)","Image title","Date"],
        "optional": ["url"]
    },
    "legal": {
        "auotfill": false,
        "required": ["author","title","date"],
        "r_alisis": ["Author(s)","Legal document title","Date"],
        "optional": ["url"]
    },
    "audio": {
        "auotfill": false,
        "required": ["author","title","date"],
        "r_alisis": ["Author(s)","Audio title","Date"],
        "optional": ["url"]
    },
    "artwork": {
        "auotfill": false,
        "required": ["author","title","date"],
        "r_alisis": ["Author(s)","Artwork title","Date"],
        "optional": ["url"]
    },
    "report": {
        "auotfill": false,
        "required": ["author","title","type","institution","date"],
        "r_alisis": ["Author(s)","Title","Type","Institution","Date"],
        "optional": ["number","version","doi","url"]
    },
    "thesis": {
        "auotfill": false,
        "required": ["author","title","type","institution","date"],
        "r_alisis": ["Author(s)","Title","Type","Institution","Date"],
        "optional": ["doi","url"]
    },
    "proceedings": {
        "auotfill": false,
        "required": ["title","date"],
        "r_alisis": ["Title", "Date"],
        "optional": ["venue","volume","part","series","doi","url"]
    },
    "patent": {
        "auotfill": false,
        "required": ["author","title","number","date"],
        "r_alisis": ["Author(s)","Title","Number","Date"],
        "optional": ["holder","type","version","url"]
    },
    "dataset": {
        "auotfill": false,
        "required": ["author","title","date"],
        "r_alisis": ["Author(s)", "Title", "Date"],
        "optional": ["edition","type","number","version","publisher","url"]
    },
    "manual": {
        "auotfill": false,
        "required": ["author","title","date"],
        "r_alisis": ["Author(s)","Title","Date"],
        "optional": ["edition","type","number","publisher","url"]
    },
    "misc": {
        "auotfill": false,
        "required": ["author", "title", "date"],
        "r_alisis": ["Author(s)","Title","Date"],
        "optional": ["subtitle","language","type","version","organization","url"]
    }
}

let data = {}

const Cite = () => {

    const updateData = (key, val) => {
        data[key] = val;
        if (val == '' ||
            val == "__null__" ||
            JSON.stringify(val) == JSON.stringify([["author","","","","",""]]) ||
            JSON.stringify(val) == JSON.stringify(['',"__null__",''])
            ) {
            delete data[key]
        }
        if (key == "date") {
            if (val[0] != '' && val[1] == "__null__" && val[2] == "") {
                data["year"] = val[0]
                delete data[key]
            }
            if (data["year"] && val[0] == "") {
                delete data["year"]
            }
            if (data["year"] && val[1] != "__null__" && val[2] != "") {
                delete data["year"]
            }
        }
    }

    const [citeStyle, setCiteStyle] = React.useState(null);
    const [mediaType, setMediaType] = React.useState(null);

    //-----------------------------------------------------------------

    const renderInputs = () => {
        let out = []
        for (let i = 0; i < media_data[mediaType]["required"].length; i++) {
            if (media_data[mediaType]["required"][i] == "author") {
                out.push(
                    <Box sx={{mb:2}}>
                        <CAuthor data={data} key={i} updateFunc={updateData}></CAuthor>
                    </Box>
                )
            }
            else if (media_data[mediaType]["required"][i] == "date") {
                out.push(
                    <Box sx={{mb:2}}>
                        <CDate data={data} key={i} label="Date" updateFunc={updateData} dataKey="date"></CDate>
                    </Box>
                )
            }
            else if (media_data[mediaType]["required"][i] == "urldate") {
                out.push(
                    <Box sx={{mb:2}}>
                        <CDate data={data} key={i} label="Date Accessed" updateFunc={updateData} dataKey="urldate"></CDate>
                    </Box>
                )
            }
            else {
                out.push(
                    <Box sx={{mb:2}}>
                        <CText data={data} key={i} label={media_data[mediaType]["r_alisis"][i]} updateFunc={updateData} dataKey={media_data[mediaType]["required"][i]}></CText>
                    </Box>
                )
            }
        }
        return out;
    }

    //----------------------------------------------------------------

    const rednerOptional = () => {
        let out = []
        if (!media_data[mediaType]["optional"]) {
            return out;
        }
        for (let i = 0; i < media_data[mediaType]["optional"].length; i++) {
            out.push(
                <COptional key={i} label={media_data[mediaType]["optional"][i]} updateFunc={updateData} dataKey={media_data[mediaType]["optional"][i]}></COptional>
            )
        }

        return (
            <Accordion disableGutters sx={{maxWidth:500, mt:2}} defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{bgcolor:"#F6F6F6"}}
                >
                <Typography variant='h6'>Optional Fields</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{display:"flex", flexDirection:"row", flexWrap:"wrap", gap:2, bgcolor:"#F6F6F6"}}>
                    {out}
                </AccordionDetails>
            </Accordion>
        )
    }

    const handleSubmit = () => {

        let out = {
            "citation": {"data": data, "type": mediaType},
            "style": citeStyle
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/addnewcite"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                data = {}
                
                setRedirect("/citations")
            }
            else if (xhr.readyState === 4 && xhr.status === 500) {
                alert("Unknow server error!")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                let resp_json = JSON.parse(xhr.responseText);
                alert("ERROR: " + resp_json["error"] + " \nMessage: " + resp_json["message"])
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const autofillRef = React.useRef(null)

    const handleArticleAutofill = () => {
        let doi = autofillRef.current.value;

        let out = {
            "type": "article",
            "doi": doi
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/citeautofill"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText);

                setRedirect("/citations")                
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                alert("Unable to create citation")
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const handleWebsiteAutofill = () => {
        let url_to = autofillRef.current.value;

        let out = {
            "type": "website",
            "url": url_to
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/citeautofill"
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText)["data"];


                data["year"] = resp_json["year"]
                data["title"] = resp_json["title"]
                data["authors"] = [["author","",resp_json["authorFirst"],"",resp_json["authorLast"],""]]
                data["url"] = resp_json["url"]
                resetSeed()

                //setRedirect("/citations")
            }
            else if (xhr.readyState === 4 && xhr.status != 200) {
                alert("Unable to create citation")
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)
    }

    const [bookRes, setBookRes] = React.useState([])
    const [bookState, setBookState] = React.useState(0)

    const [timer, setTimer] = React.useState(null);
    const handleBookSearch = () => {
        setBookState(1)
        setBookRes([])

        let query = autofillRef.current.value;

        if(query.length < 3) {
            return;
        }

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
            
                
                let out = {
                    "type": "book",
                    "query": query,
                    "req": "search"
                }

                let xhr = new XMLHttpRequest();
                let url = "/api/citeautofill"
                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json")
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let resp_json = JSON.parse(xhr.responseText);

                        setBookState(2)

                        setBookRes(resp_json)
                    }
                    else if (xhr.readyState === 4 && xhr.status != 200) {
                        alert("Unable to create citation")
                        setBookState(0)
                    }
                }
                let post_json = JSON.stringify(out)
                xhr.send(post_json)
                

            }, 1500)
        );
    }

    const autoFillBook = (i) => {
        data["year"] = bookRes[i]["Year"]
        data["title"] = bookRes[i]["Title"]
        data["publisher"] = bookRes[i]["Publisher"]
        let authorArray = []
        let authors = bookRes[i]["Author"].split(",")
        for (let j = 0; j < authors.length; j++) {
            let auth = authors[j]
            let first = auth.split(" ")[0]
            let last = auth.split(" ")[auth.split(" ").length - 1]
            authorArray.push(["author","",first,"",last,""])
        }
        data["authors"] = authorArray
        resetSeed()
    }

    const renderBooks = () => {
        let out = []

        for (let i = 0; i < bookRes.length; i++) {
            out.push(
                <>
                <Box sx={{width:400, maxWidth:"90vw", textAlign:"left", borderBottom:"1px solid", mt:1}}>
                    <Typography>Author: <b>{bookRes[i]["Author"]}</b></Typography>
                    <Typography>Title: {bookRes[i]["Title"]}</Typography>
                    <Typography>Year: {bookRes[i]["Year"]}</Typography>
                    <Button onClick={() => autoFillBook(i)} sx={{mt:1, mb:1}}>Autofill</Button>
                </Box>
                </>
            )
        }

        return out
    }

    const [redirect, setRedirect] = React.useState(null)
    const renderNavigate = () => {
        if (redirect != null) {
            return <Navigate to={redirect} />
        }
    }

    const [seed, setSeed] = React.useState(1); // used to reload
    const resetSeed = () => {
        setSeed(Math.random());
    }

    return (
        <>
        {renderNavigate()}
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:5, mt:3}}>
            <Typography variant='h6'>Select Citation Style</Typography>
            <Autocomplete 
                clearOnEscape
                sx={{ width: 300 }}
                options={styles}
                onChange={(e, newVal) => setCiteStyle(newVal)}
                renderInput={(params) => (
                    <TextField {...params} label="Citation Style" variant="filled" />
                )}
            />
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:5}}>
            <Typography variant='h6'>Select Media Type</Typography>
            <Autocomplete 
                clearOnEscape
                sx={{ width: 300 }}
                options={media}
                onChange={(e, newVal) => setMediaType(newVal)}
                renderInput={(params) => (
                    <TextField {...params} label="Media Type" variant="filled" />
                )}
            />
        </Box>
        {( mediaType == "article" ?
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <Box sx={{padding:2, border:"2px solid", borderRadius:2, display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <Typography variant='h5'>New! DOI autofill</Typography>
                    <Typography sx={{maxWidth:300, textAlign:"center", mt:1}} variant='body2'>Enter the DOI of your article to automatically create your citation</Typography>
                    <TextField inputRef={autofillRef} label="DOI" sx={{mt:2, width:300, maxWidth:"100vw"}} />
                    <Button onClick={handleArticleAutofill} variant='contained' sx={{mt:2, width:"100%"}}>Create Citation</Button>
                </Box>
            </Box>
            :
            ""
        )}
        {( mediaType == "book" ?
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <Box sx={{padding:2, border:"2px solid", borderRadius:2, display:"flex", flexDirection:"column", alignItems:"center", maxHeight:750, overflow:"auto"}}>
                    <Typography variant='h5'>New! Book autofill</Typography>
                    <Typography sx={{maxWidth:300, textAlign:"center", mt:1}} variant='body2'>Search for the title of your book and click the appropriate "Autofill" button. Query must be longer than 3 letters</Typography>
                    <TextField onChange={handleBookSearch} inputRef={autofillRef} label="Book title" sx={{mt:2, width:400, maxWidth:"100vw"}} />
                    {renderBooks()}
                    {( bookState == 1 ?
                        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                            <link rel="stylesheet" href="/loader.css"></link>
                            <div style={{filter:"invert(1)", transform:"scale(0.7)"}} className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </Box>
                        :
                        ""
                    )}
                </Box>
            </Box>
            :
            ""
        )}
        {( mediaType == "website" ?
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <Box sx={{padding:2, border:"2px solid", borderRadius:2, display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <Typography variant='h5'>New! Website autofill</Typography>
                    <Typography sx={{maxWidth:300, textAlign:"center", mt:1}} variant='body2'>Enter the URL of your website to automatically autofill fields</Typography>
                    <TextField inputRef={autofillRef} label="URL" sx={{mt:2, width:300, maxWidth:"100vw"}} />
                    <Button onClick={handleWebsiteAutofill} variant='contained' sx={{mt:2, width:"100%"}}>Autofill</Button>
                </Box>
            </Box>
            :
            ""
        )}
        {( mediaType != null ?

            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mt:5, mr:2, ml:2}}>
                <Box key={seed} sx={{display:"flex", flexDirection:"column", border:"2px solid", padding:5, borderRadius:2, mb:5}}>
                    {renderInputs()}
                    {rednerOptional()}
                    <Button onClick={handleSubmit} sx={{mt:2}} variant="contained">Create Citation</Button>
                </Box>
            </Box>
            :
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mt:10}}>
                <Typography variant='h5'>Please select a media type!</Typography>
            </Box>
        )}
        </>
    );
}

/*
<CAuthor updateFunc={updateData}></CAuthor>
<CDate label="Date" updateFunc={updateData} dataKey="date"></CDate>
<CText label="Title" updateFunc={updateData} dataKey="title"></CText>
<COptional label="url" updateFunc={updateData} dataKey="url"></COptional>
*/
 
export default Cite;