import * as React from 'react';
import {Route, Link, Routes, useLocation, Navigate} from 'react-router-dom';
import { Typography, Box, AppBar, Toolbar, Button, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, ListItemIcon} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useCookies } from 'react-cookie';

function TopBar(props) {

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['login_state_cont']);

  const logout = () => {
    
  }


  const renderDrawerList = () => {
    return(
      <Box sx={{maxWidth:"60vw", width:350}}>
        <List>
          {( cookies["login_state_cour"] != "true" ?
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/">
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          :
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/home">
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          )}
          <ListItem disablePadding>
              <ListItemButton component={Link} to="/contact">
                <ListItemText primary="Contact" />
              </ListItemButton>
            </ListItem>
          <Divider />
          {( cookies["login_state_cour"] != "true" ?
          <>
          <ListItem disablePadding>
              <ListItemButton component={Link} to="/login">
                <ListItemText primary="Login" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
            <ListItemButton component={Link} to="signup">
              <ListItemText primary="Sign Up" />
            </ListItemButton>
          </ListItem>
          </>
            :
            <ListItem disablePadding>
              <ListItemButton onClick={() => setCookie("login_state_cour", "false-l")} component="a" href="/api/logout">
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
            )}
            <Divider />
            {( cookies["login_state_cour"] == "true" ?
            <>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/home/feed">
                <ListItemText primary="Your Feed" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/home/search">
                <ListItemText primary="Search" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/home/ask">
                <ListItemText primary="Ask question" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/home/upload">
                <ListItemText primary="Upload file" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/home/profile">
                <ListItemText primary="My profile" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/home/myposts">
                <ListItemText primary="My Posts" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/accountactions">
                <ListItemText primary="Account Actions" />
              </ListItemButton>
            </ListItem>
            </>
            : ""
            )}
        </List>
      </Box>
    )
  }



  return (
    <>
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      {renderDrawerList()}
    </Drawer>

      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Button variant="text" color="text" sx={{  textTransform: "none"}}>
            <Box
            component="img"
            sx={{
              width: 45,
              pr: 2,
              pt: 2,
              pb: 2,
              cursor: "pointer"
            }}
            alt="Courseology logo"
            src="/logo.png"
            />
            <Typography variant="h5" component="div" >
              Courseology
            </Typography>
          </Button>
          <Box sx={{ flexGrow: 1}}></Box>
          {( cookies["login_state_cour"] == "true" ? "" :
          <Box sx={{ display: {xs: "none", sm: "block"}}} >
            <Button component={Link} to="/signup" variant="contained" sx={{ ml: 2}}>Sign Up For Free</Button>
            <Button component={Link} to="/login" variant="outlined" sx={{ ml: 2 }}>Login</Button>
          </Box>
          )}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon fontSize="xmedium" />
          </IconButton>
        </Toolbar>
      </AppBar>
    <Divider></Divider>
    </>
  );
}

export default TopBar;
