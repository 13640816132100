import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Skeleton, Select, ButtonGroup, TextField, InputLabel, FormControl, Card, CardContent, Icon, CardMedia} from '@mui/material';
import { MathJax } from 'better-react-mathjax';

import PostRenderer from '../basic/PostRenderer';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const HomeTabMyPosts = () => {

    const [curPosts, setCurPosts] = React.useState(null)

    const [bs, setBs] = React.useState(0)
    const [page, setPage] = React.useState(1)

    let buttonSelectLookUp = ["self", "voted", "answered"]

    React.useEffect(() => {
        setCurPosts(null)
        if (page < 1 && page != ""){setPage(1)}
        if (page == ""){return}

        let out = {
            "post_relation":buttonSelectLookUp[bs],
            "page":page
        }

        let xhr = new XMLHttpRequest();
        let url = "/api/myposts"
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let resp_json = JSON.parse(xhr.responseText)
                setCurPosts(resp_json)
            }
            else if (xhr.readyState === 4) {
                alert("Unknow server error!")
            }
        }
        let post_json = JSON.stringify(out)
        xhr.send(post_json)

    }, [bs, page]);

    const renderSkeletons = () => {
        let out = []
        for (let i=0; i<5; i++) {
            out.push(
                <Card sx={{width:"100%", bgcolor:"#F6F6F6", mb:2}}>
                    <CardContent>
                        <Typography variant="h5"><Skeleton /></Typography>
                        <Typography variant="overline"><Skeleton /></Typography>
                        <Typography sx={{mt:2}} variant="body2">
                            <MathJax dynamic hideUntilTypeset={"first"} inline><Skeleton /></MathJax>
                        </Typography>
                        <Skeleton sx={{width:"50%"}} />
                        <Skeleton sx={{width:50}} />
                    </CardContent>
                </Card>
            )
        }
        return (
            <Box sx={{height:"50vh", overflow:"hidden"}}>
                <Box sx={{position:"absolute", left:"0px", height:"100%", width:"100%", bgcolor:"green", zIndex:10, background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%)"}}></Box>
                {out}
            </Box>
        )
    }

    const nextDisabled = () => {
        if (curPosts == null) {
            return true
        }
        else if (curPosts["res"].length == 0) {
            return true
        }
        return false;
    }

    return (
        <>
        <Box>
        <Box sx={{width:"95%", maxWidth:900, display:"flex", margin:"auto"}}>
            <Box sx={{display:"flex", justifyContent:"center", width:"100%", mt:3, transform:{sm:"scale(1)", xs:"scale(0.85)"}}}>
                <Box sx={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button onClick={() => {setBs(0); setPage(1)}} variant={(bs==0?"contained":"outlined")}>My Posts</Button>
                        <Button onClick={() => {setBs(1); setPage(1)}} variant={(bs==1?"contained":"outlined")}>Voted</Button>
                        <Button onClick={() => {setBs(2); setPage(1)}} variant={(bs==2?"contained":"outlined")}>Answered</Button>
                    </ButtonGroup>
                    <TextField onChange={(e) => setPage(e.target.value)} label="Page" type="number" size="small" sx={{maxWidth:100, mt:2}} value={page} />
                </Box>
            </Box>

        </Box>
            <Box sx={{display:"flex", flexDirection:"column", mt:3}}>
                {( curPosts != null
                ? 
                    //renderCards()
                    <PostRenderer posts={curPosts["res"]} />
                :
                <Box sx={{maxWidth:900, paddingX:"25px", marginY:2, position:"relative", left:"50%", transform:"translateX(-50%)"}}>
                    {renderSkeletons()}
                </Box>
                )}
            </Box>

            <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", mt:3}}>
                <Button disabled={page == 1} onClick={() => setPage(parseInt(page) - 1)} startIcon={<ArrowBackIosIcon />} variant='outlined' sx={{mr:3}}>Prev</Button>
                <Button disabled={nextDisabled()} onClick={() => setPage(parseInt(page) + 1)} endIcon={<ArrowForwardIosIcon />} variant='outlined'>Next</Button>
            </Box>

            <Box sx={{mb:10}}></Box>
        </Box>
        </>
    );
}
 
export default HomeTabMyPosts;