import * as React from 'react';
import {Route, Link, Routes, useLocation, useParams} from 'react-router-dom';
import { Typography, Box, Chip, Button, Divider, Container, Grid, Tabs, Tab, Select, MenuItem, TextField, IconButton, Paper, FormControl, InputLabel, NativeSelect} from '@mui/material';

const COptional = (props) => {
    
    const handleChange = (e) => {
        props["updateFunc"](props["dataKey"],e.target.value)
    }

    return (
        <>
        <Box sx={{maxWidth:500}}>
            <TextField variant='standard' onChange={(e) => handleChange(e)} size="small" label={props["label"]} />
        </Box>
        </>
    );
}
 
export default COptional;